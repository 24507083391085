<template>
  <div class="max-w-4xl mx-auto px-5 mt-8">
    <div class="border border-rGrayDarker rounded-md bg-rGrayLight p-8 pb-9 text-2xl font-light flex items-center gap-x-8">
      <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0">
        <path d="M32.1563 12.0625L5.6875 56.25C5.14178 57.1951 4.85303 58.2665 4.84997 59.3579C4.84691 60.4492 5.12966 61.5223 5.67009 62.4704C6.21051 63.4185 6.98977 64.2086 7.93034 64.762C8.87091 65.3155 9.94 65.613 11.0313 65.625H63.9688C65.06 65.613 66.1291 65.3155 67.0697 64.762C68.0102 64.2086 68.7895 63.4185 69.3299 62.4704C69.8704 61.5223 70.1531 60.4492 70.15 59.3579C70.147 58.2665 69.8582 57.1951 69.3125 56.25L42.8438 12.0625C42.2867 11.1441 41.5023 10.3847 40.5662 9.85775C39.6302 9.33077 38.5742 9.05392 37.5 9.05392C36.4258 9.05392 35.3698 9.33077 34.4338 9.85775C33.4978 10.3847 32.7134 11.1441 32.1563 12.0625Z" fill="#F2F2FC" stroke="#EF4136" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.5 53.125H37.5317" stroke="#EF4136" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.5 28.125V44.7917" stroke="#EF4136" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>

      <div class="text-rGrayDarker">
        <p class="max-w-xl">
          {{ $t('404.title')}}<br/>
          {{ $t('404.content')}}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const NotFound = defineComponent({})

export default NotFound
</script>
