
import { defineComponent, ref, Ref, watch, computed } from 'vue'
import { Validator, ValidatorAddress, AmountT } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import LoadingIcon from '@/components/LoadingIcon.vue'
import BigNumber from 'bignumber.js'
import BigAmount from '@/components/BigAmount.vue'
import Tooltip from '@/components/Tooltip.vue'
import { useRoute, useRouter } from 'vue-router'
import ClickToCopy from '@/components/ClickToCopy.vue'
import useRadix from '@/composables/useRadix'
import useValidators from '@/composables/useValidators'
import { useI18n } from 'vue-i18n'
import { checkValidatorUrlExploitable } from '@/helpers/urlHelpers'
import TokenSymbol from '@/components/TokenSymbol.vue'

BigNumber.set({
  ROUNDING_MODE: BigNumber.ROUND_FLOOR,
  EXPONENTIAL_AT: [-30, 30]
})

export default defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    LoadingIcon,
    TokenSymbol,
    Tooltip
  },

  props: {
    validatorId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const { t } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const router = useRouter()
    const validator: Ref<Validator | null> = ref(null)
    const isLoadingValidator: Ref<boolean> = ref(true)

    const { radix, network, nativeToken } = useRadix()
    const {
      bottomValidators,
      validators,
      isLoadingValidators,
      totalStaked,
      fetchValidators
    } = useValidators(radix, network.value)

    fetchValidators()

    const fetchData = async (validatorId: string) => {
      const initialAddress = ValidatorAddress.fromUnsafe(validatorId)
      if (initialAddress.isErr()) {
        throw Error('Invalid Address')
      }

      const validatorRes = await firstValueFrom(radix.ledger.lookupValidator(initialAddress.value)) as Validator
      validator.value = validatorRes
      isLoadingValidator.value = false
    }

    try {
      fetchData(props.validatorId)
    } catch {
      router.push({ path: '/404', query: { term: route.params.validatorId } })
    }

    watch(() => props.validatorId, (newId: string) => {
      isLoadingValidator.value = true
      fetchData(newId)
    })

    const validatorName = computed(() => {
      if (validator.value && validator.value.name) return validator.value.name
      return 'No name provided'
    })

    const validatorAddress = computed(() => {
      if (validator.value) {
        const address = validator.value.address.toString()
        return `${address.slice(0, 11)}...${address.slice(-11)}`
      }
      return ''
    })

    const ownerAddressURL = computed(() => {
      if (validator.value) {
        return `/#/accounts/${validator.value.ownerAddress.toString()}`
      }
      return ''
    })

    const stakePercentage = computed(() => {
      if (!validator.value) return '0'
      const bigVS = new BigNumber(validator.value.totalDelegatedStake.toString())
      const bigTS = new BigNumber(totalStaked.value.toString())
      return bigVS.dividedBy(bigTS).multipliedBy(100).toFixed(2)
    })

    const stakePercentageContent = computed(() => {
      if (!validators.value || validators.value.length <= 0 || !validator.value) return t('general.loading')
      const activeValidatorAddress = validator.value.address
      const maybeVal = validators.value.find(v => v.address.equals(activeValidatorAddress))
      return maybeVal !== undefined
        ? `${stakePercentage.value}%`
        : 'Not in <a href="https://learn.radixdlt.com/article/how-are-validators-selected-for-the-olympia-mainnet" class="text-rBlue" target="__blank">active top 100 validators</a> by stake'
    })

    const uptimeContent = computed(() => {
      if (!validators.value || validators.value.length <= 0 || !validator.value) return '-'
      const activeValidatorAddress = validator.value.address
      const maybeVal = bottomValidators.value.find(v => v.validator.address.equals(activeValidatorAddress))
      return maybeVal !== undefined
        ? '-'
        : `${validator.value.uptimePercentage}%`
    })

    const validatedValidatorUrl = computed(() => {
      if (!validator.value) return false
      return checkValidatorUrlExploitable(validator.value.infoURL.toString())
    })

    const displayAmount = (amount: AmountT): string => {
      const bigNumber = new BigNumber(amount.toString())
      const shiftedAmount = bigNumber.shiftedBy(-18) // Atto
      const bigOne = new BigNumber(1)
      const bigZero = new BigNumber(0)
      if (shiftedAmount.isGreaterThan(bigOne)) {
        return shiftedAmount.toFormat(0)
      } else if (shiftedAmount.isGreaterThan(bigZero)) {
        return '< 1'
      } else {
        return '0'
      }
    }

    return {
      nativeToken,
      // Refs
      validator,
      validators,
      isLoadingValidators,
      isLoadingValidator,
      totalStaked,
      // Computed Properties
      validatorName,
      validatorAddress,
      ownerAddressURL,
      stakePercentage,
      stakePercentageContent,
      validatedValidatorUrl,
      uptimeContent,

      // Methods
      displayAmount
    }
  }
})
