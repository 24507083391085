<template>
  <dl class="border border-rGray rounded-md bg-white mb-5 divide-y divide-gray-200">
    <div class="p-5 flex flex-col gap-2">
      <dt class="text-sm font-light text-rGrayDarker text-left">
        {{ $t('transaction.typeLabel') }}
      </dt>
      <dd class="font-light text-sm text-rBlack mt-0 col-span-3 inline-flex items-center">
        {{ action.type  }}
      </dd>
    </div>
    <div class="p-5 flex flex-col gap-2">
      <dt class=" flex justify-between text-sm font-light text-rGrayDarker text-left">
        {{ $t('transaction.fromLabel') }} <span><click-to-copy :text="action.from_account.toString()" /></span>
      </dt>
      <dd class="font-light text-sm text-rBlack mt-0 col-span-3 break-words font-mono">
        <router-link class="text-rBlue font-mono" :to="`/accounts/${action.from_account.toString()}`">{{ action.from_account.toString() }}</router-link>
      </dd>
    </div>
    <div class="p-5 flex flex-col gap-2">
      <dt class="flex justify-between text-sm font-light text-rGrayDarker text-left">
        {{ $t('transaction.validatorLabel') }} <span><click-to-copy :text="action.to_validator.toString()" /></span>
      </dt>
      <dd class="font-light text-sm text-rBlack mt-0 col-span-3 break-words font-mono">
        <router-link class="text-rBlue font-mono" :to="`/validators/${action.to_validator.toString()}`">{{ action.to_validator.toString() }}</router-link>
      </dd>
    </div>
    <div class="p-5 flex flex-col gap-2">
      <dt class="text-sm font-light text-rGrayDarker flex flex-row items-center">
        {{ $t('transaction.amountLabel') }}
      </dt>
      <dd class="font-light text-sm text-rBlack mt-0 col-span-3 flex flex-row items-center">
        <big-amount :amount="action.amount" :format="false" class="text-lg mr-4 font-thin" />
        <token-symbol
          :hasGreyBackground="true"
          :symbol="action.rri.name.toUpperCase()"
          :rri="action.rri.toString()"
        >
        </token-symbol>
      </dd>
    </div>
  </dl>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ExecutedStakeTokensAction } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionExpandedListItemStakeTokens = defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedStakeTokensAction>,
      required: true
    }
  }
})

export default ActionExpandedListItemStakeTokens
</script>
