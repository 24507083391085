import { ref, Ref } from 'vue'
import { Amount, AmountT, Network, Validator, Validators } from '@radixdlt/application'
import { RadixInterface } from './useRadix'
import { firstValueFrom } from 'rxjs'
import BigNumber from 'bignumber.js'

const zero = Amount.fromUnsafe(0)._unsafeUnwrap()

const isLoadingValidators: Ref<boolean> = ref(true)
const totalStaked = ref(Amount.fromUnsafe(0)._unsafeUnwrap()) as Ref<AmountT>

type ValidatorWithTotalStake = { validator: Validator, totalStake: number }
type ValidatorsWithTotalStakes = Array<ValidatorWithTotalStake>
type ValidatorAccumulator = { five: ValidatorsWithTotalStakes, three: ValidatorsWithTotalStakes, middle: ValidatorsWithTotalStakes, bottom: ValidatorsWithTotalStakes }

const validators: Ref<Array<Validator>> = ref([])
const registeredValidators: Ref<Array<Validator>> = ref([])
const fivePercentStakeValidators: Ref<ValidatorsWithTotalStakes> = ref([])
const threePercentStakeValidators: Ref<ValidatorsWithTotalStakes> = ref([])
const middleValidators: Ref<ValidatorsWithTotalStakes> = ref([])
const bottomValidators: Ref<ValidatorsWithTotalStakes> = ref([])

const stakePercentage = (validaterStake: AmountT, totalStaked: AmountT):number => {
  const bigVS = new BigNumber(validaterStake.toString())
  const bigTS = new BigNumber(totalStaked.toString())
  return bigVS.dividedBy(bigTS).multipliedBy(100).toNumber()
}

export default function useValidators (radix: RadixInterface, network: Network) {
  const fetchValidators = async () => {
    if (validators.value.length > 0) {
      return
    }

    const validatorsRes = await firstValueFrom(radix.ledger.validators(network)) as Validators

    isLoadingValidators.value = false

    validators.value = validatorsRes.validators
    registeredValidators.value = validators.value.filter((v) => v.registered)
    totalStaked.value = registeredValidators.value.slice(0, 100).reduce((a, b) => { return a.add(b.totalDelegatedStake) }, zero)

    const { five, three, middle, bottom } = registeredValidators.value.reduce((accum: ValidatorAccumulator, v: Validator, index) => {
      const vWithStake: ValidatorWithTotalStake = { validator: v, totalStake: stakePercentage(v.totalDelegatedStake, totalStaked.value) }
      if (vWithStake.totalStake >= 5) {
        accum.five.push(vWithStake)
      }

      if (vWithStake.totalStake >= 3 && vWithStake.totalStake < 5) {
        accum.three.push(vWithStake)
      }

      if (vWithStake.totalStake < 3 && index < 100) {
        accum.middle.push(vWithStake)
      }

      if (vWithStake.totalStake < 3 && index >= 100) {
        accum.bottom.push(vWithStake)
      }

      return accum
    }, { five: [], three: [], middle: [], bottom: [] })

    fivePercentStakeValidators.value = five
    threePercentStakeValidators.value = three
    middleValidators.value = middle
    bottomValidators.value = bottom
  }

  return {
    validators,
    isLoadingValidators,
    totalStaked,
    fivePercentStakeValidators,
    threePercentStakeValidators,
    middleValidators,
    bottomValidators,

    fetchValidators
  }
}
