
import { defineComponent, PropType } from 'vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import { Validator, AmountT } from '@radixdlt/application'
import BigNumber from 'bignumber.js'
import { formatValidatorAddressForDisplay } from '@/helpers/formatter'
import Tooltip from '@/components/Tooltip.vue'
import { checkValidatorUrlExploitable } from '@/helpers/urlHelpers'

export default defineComponent({
  components: {
    ClickToCopy,
    Tooltip
  },

  props: {
    validator: {
      type: Object as PropType<Validator>,
      required: true
    },
    percentStaked: {
      type: String,
      required: false
    },
    hideUptime: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    validatorName (): string {
      if (this.validator.name) return this.validator.name
      return 'No name provided'
    },
    validatorAddress (): string {
      return formatValidatorAddressForDisplay(this.validator.address)
    },
    validatorURL (): string {
      return `/#/validators/${this.validator.address.toString()}`
    },
    uptime (): string {
      return (Math.round(Number(this.validator.uptimePercentage) * 10) / 10).toFixed(1)
    },
    uptimeContent (): string {
      return this.hideUptime
        ? '-'
        : `${this.uptime}%`
    },
    validatedValidatorUrl (): boolean {
      if (!this.validator) {
        return false
      }
      return checkValidatorUrlExploitable(this.validator.infoURL.toString())
    }
  },

  methods: {
    displayAmount (amount: AmountT): string {
      const bigNumber = new BigNumber(amount.toString())
      const shiftedAmount = bigNumber.shiftedBy(-18) // Atto
      const bigOne = new BigNumber(1)
      const bigZero = new BigNumber(0)
      if (shiftedAmount.isGreaterThan(bigOne)) {
        return shiftedAmount.toFormat(0)
      } else if (shiftedAmount.isGreaterThan(bigZero)) {
        return '< 1'
      } else {
        return '0'
      }
    },
    formatPercentage (percentStaked: number): number {
      return +(Math.floor(100 * percentStaked) / 100).toFixed(2)
    }
  }
})
