<template>
  <div>
    <span v-if="encrypted" class="text-rGrayDark italic">Encrypted message</span>
    <span v-if="!encrypted && decodedMessage" class="text-rDarkBlue">{{ decodedMessage }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRef, ref } from 'vue'
import { decodeMessage, isEncrypted } from '@/helpers/message'

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const message = toRef(props, 'message')
    const encrypted = ref(false)
    const decodedMessage = ref('')

    if (isEncrypted(message.value)) {
      encrypted.value = true
    }
    const msg = decodeMessage(message.value)

    if (msg) {
      decodedMessage.value = msg
    }

    return {
      encrypted,
      decodedMessage
    }
  }
})
</script>
