<template>
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M16.6924 12.8687L8.34618 2.28857L0 12.8687H4.96402V18.5628H11.7284V12.8687H16.6924Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16.6924" height="16.2743" fill="white" transform="translate(0 2.28857)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
})
</script>
