
import { defineComponent, PropType } from 'vue'
import { ExecutedTransferTokensAction } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import { AccountAddressT } from '@radixdlt/account'
import { formatWalletAddressForDisplay } from '@/helpers/formatter'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionListItemTransferTokens = defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedTransferTokensAction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeAddress: {
      type: Object as PropType<AccountAddressT>,
      required: false
    }
  },

  computed: {
    toAddress (): string {
      return `/accounts/${this.action.to_account.toString()}`
    },

    isRecipient (): boolean {
      if (!this.activeAddress) return false
      return this.action.to_account.toString() === this.activeAddress.toString()
    },

    fromAddress (): string {
      return `/accounts/${this.action.from_account.toString()}`
    }
  },

  methods: {
    shortenedAddress (address: AccountAddressT): string {
      return formatWalletAddressForDisplay(address)
    }
  }
})

export default ActionListItemTransferTokens
