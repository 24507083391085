<template>
  <a
    :href="rriUrl"
    class="group cursor-pointer relative inline-flex font-light text-rGrayMark py-0.5 px-1 rounded borderself-end uppercase"
    :class="{ 'bg-rGrayLight border border-rGray': hasGreyBackground }"
  >
    {{ symbol }}
    <div class="absolute invisible group-hover:visible -mt-full bg-rGrayLightest text-rBlack bottom-full text-xs p-1 rounded-sm shadow border border-solid border-rGrayLight">
      {{ rri }}
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getRRIExplorerUrl } from '@/helpers/urlHelpers'

const TokenSymbol = defineComponent({
  props: {
    hasGreyBackground: {
      type: Boolean,
      required: false
    },
    rri: {
      type: String,
      required: true
    },
    symbol: {
      type: String,
      required: true
    }
  },

  computed: {
    rriUrl (): string {
      return getRRIExplorerUrl(this.rri)
    }
  }
})

export default TokenSymbol
</script>
