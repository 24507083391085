
import { defineComponent, PropType } from 'vue'
import { ExecutedOtherAction } from '@radixdlt/application'

const ActionExpandedListItemOther = defineComponent({
  props: {
    action: {
      type: Object as PropType<ExecutedOtherAction>,
      required: true
    }
  }
})

export default ActionExpandedListItemOther
