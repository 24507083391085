
import { defineComponent, PropType } from 'vue'
import { ExecutedUnstakeTokensAction } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionExpandedListItemUnstakeTokens = defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedUnstakeTokensAction>,
      required: true
    }
  }
})

export default ActionExpandedListItemUnstakeTokens
