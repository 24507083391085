<template>
  <div>
    <div class="border rounded-t-md p-4 mt-4 bg-rGrayLight">
      <div class="inline-flex min-w-full">
        <router-link :to="transactionAddress" class="text-rBlue font-mono">
          {{ shortenedTransactionID }}
        </router-link>
        <click-to-copy :text="transactionID" />
        <div class="ml-auto">{{ sentAt }}</div>
      </div>
    </div>
    <div :class="{'border-l border-r py-4 px-4': true, 'border-b rounded-b-md': !transaction.message}">
      <div v-for="(action, i) in relatedActions" :key="i">
        <action-list-item-stake-tokens
          v-if="action.type === 'StakeTokens'"
          :action="action"
          :index="i"
        />
        <action-list-item-unstake-tokens
          v-else-if="action.type === 'UnstakeTokens'"
          :action="action"
          :index="i"
        />
        <action-list-item-transfer-tokens
          v-else-if="action.type === 'TokenTransfer'"
          :action="action"
          :activeAddress="activeAddress"
          :index="i"
        />
        <action-list-item-other v-else :action="action" :index="i" />
      </div>
    </div>
    <div class="border rounded-b-md py-2 bg-rGrayLight grid grid-cols-10" v-if="decodedMessage">
      <div class="flex r ml-4 pt-2">
        <svg
          width="14"
          height="17"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class=""
          v-if="isEncrypted"
        >
          <path
            d="M2.04883 4.93512V3.79987C2.04883 2.25355 3.30238 1 4.8487 1C6.39502 1 7.64857 2.25355 7.64857 3.79987V4.93512"
            stroke="#7A99AC"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
          <path
            d="M1 11.4001V4.84473H2.13447H7.58739H8.72185V11.0556H2.60558"
            stroke="#7A99AC"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
        </svg>
        <svg
          width="21"
          height="18"
          viewBox="0 0 17 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-else
        >
          <path
            d="M11.9125 5.125H11.9043M8.6425 5.125H8.63432M5.3725 5.125H5.36432M11.095 9.625H14.365C15.268 9.625 16 8.95343 16 8.125V2.125C16 1.29657 15.268 0.625 14.365 0.625H2.92C2.01701 0.625 1.285 1.29657 1.285 2.125V8.125C1.285 8.95343 2.01701 9.625 2.92 9.625H7.0075L11.095 13.375V9.625Z"
            stroke="#7A99AC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <transaction-message v-if="transaction.message" :message="transaction.message" class="col-span-9 pr-8 pt-1"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ExecutedTransaction,
  ExecutedAction,
  ActionType
} from '@radixdlt/application'
import { DateTime } from 'luxon'
import ActionListItemStakeTokens from '@/components/ActionListItemStakeTokens.vue'
import ActionListItemUnstakeTokens from '@/components/ActionListItemUnstakeTokens.vue'
import ActionListItemTransferTokens from '@/components/ActionListItemTransferTokens.vue'
import ActionListItemOther from '@/components/ActionListItemOther.vue'
import TransactionMessage from './TransactionMessage.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import { AccountAddressT } from '@radixdlt/account'
import { formatTransactionAddressForDisplay } from '@/helpers/formatter'
import { decodeMessage, isEncrypted } from '@/helpers/message'

export default defineComponent({
  components: {
    ActionListItemStakeTokens,
    ActionListItemTransferTokens,
    ActionListItemUnstakeTokens,
    ActionListItemOther,
    ClickToCopy,
    TransactionMessage
  },

  props: {
    transaction: {
      type: Object as PropType<ExecutedTransaction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeAddress: {
      type: Object as PropType<AccountAddressT>,
      required: false
    }
  },

  computed: {
    sentAt (): string {
      return DateTime.fromJSDate(this.transaction.sentAt).toLocaleString(
        DateTime.DATETIME_SHORT
      )
    },

    transactionAddress (): string {
      return `/transactions/${this.transaction.txID.toString()}`
    },

    transactionID (): string {
      return this.transaction.txID.toString()
    },

    shortenedTransactionID (): string {
      return formatTransactionAddressForDisplay(this.transaction.txID)
    },

    decodedMessage (): string | false | null {
      if (!this.transaction.message) return null
      return decodeMessage(this.transaction.message)
    },

    isEncrypted (): boolean {
      if (!this.transaction.message) return false
      return isEncrypted(this.transaction.message)
    },

    relatedActions (): ExecutedAction[] {
      if (!this.activeAddress) return this.transaction.actions

      return this.transaction.actions.filter((action: ExecutedAction) => {
        let related
        switch (action.type) {
          case ActionType.TOKEN_TRANSFER:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.to_account ||
                this.activeAddress.toString() === action.from_account
              : false
            break
          case ActionType.STAKE_TOKENS:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.from_account
              : false
            break
          case ActionType.UNSTAKE_TOKENS:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.to_account
              : false
            break
          case ActionType.OTHER:
            related = false
            break
        }
        return related
      })
    }
  }
})
</script>
