
import { ComputedRef, defineComponent, Ref, watch, ref, computed } from 'vue'
import { Token, Amount, AmountT, AccountAddress, AccountAddressT, SimpleExecutedTransaction, TransactionHistoryRequestInput, ExecutedTransferTokensAction, ExecutedUnstakeTokensAction, ExecutedStakeTokensAction, ExecutedOtherAction, ExecutedAction, ExecutedTransaction } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import { useRouter } from 'vue-router'
import PaginatedTransactions from '@/components/PaginatedTransactions.vue'
import BigAmount, { asBigNumber } from '@/components/BigAmount.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import TokenSymbol from '@/components/TokenSymbol.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import LoadingIconLarge from '@/components/LoadingIconLarge.vue'
import { add } from '@/helpers/arithmetic'
import useRadix from '@/composables/useRadix'
import { Decoded } from '@radixdlt/application/dist/api/open-api/_types'
import Observed from '@/helpers/observed'
import PapaParse from 'papaparse'
import { subWeeks, subMonths, subYears, parse, format, isEqual } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import { DatetimeFormat } from 'vue-i18n'

const PAGE_SIZE = 30

const zero = Amount.fromUnsafe(0)._unsafeUnwrap()

export default defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    LoadingIcon,
    LoadingIconLarge,
    PaginatedTransactions,
    TokenSymbol
  },

  props: {
    accountId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const { radix, nativeToken } = useRadix()
    const tokenBalances: Ref<Observed<ReturnType<typeof radix.ledger.tokenBalancesForAddress>> | null> = ref(null)
    const activeStakes: Ref<Observed<ReturnType<typeof radix.ledger.stakesForAddress>> | null> = ref(null)
    const activeUnstakes: Ref<Observed<ReturnType<typeof radix.ledger.unstakesForAddress>> | null> = ref(null)
    const transactionHistory: Ref<Observed<ReturnType<typeof radix.ledger.transactionHistory>>> = ref({ cursor: '', transactions: [] })

    const tokenBalanceIsValid = ref(false) as Ref<boolean>
    const transactionHistoryIsValid = ref(false) as Ref<boolean>
    const address: Ref<AccountAddressT | null> = ref(null)
    const router = useRouter()
    const accountError: Ref<Error | null> = ref(null)
    const transactionError: Ref<Error | null> = ref(null)
    const canGoNext: Ref<boolean> = ref(false)
    const cursorStack: Ref<string[]> = ref([])

    const showExportDropdown: Ref<boolean> = ref(false)
    const showCustomExportButtonEnabled: Ref<boolean> = ref(false)
    const toDate: Ref<string> = ref('')
    const fromDate: Ref<string> = ref('')
    const showDisabledSVG = ref(true)
    const showDateError = ref('')
    const showLoader: Ref<boolean> = ref(false)
    const showExportSuccess = ref(false)

    const fetchDataForAccount = async (accountId: string) => {
      const initialAddress = AccountAddress.fromUnsafe(accountId)
      if (initialAddress.isErr()) {
        throw Error('Invalid Address')
      }
      address.value = initialAddress.value
      activeStakes.value = await firstValueFrom(radix.ledger.stakesForAddress(initialAddress.value))
      activeUnstakes.value = await firstValueFrom(radix.ledger.unstakesForAddress(initialAddress.value))
      tokenBalances.value = await firstValueFrom(radix.ledger.tokenBalancesForAddress(initialAddress.value))
      tokenBalanceIsValid.value = true
    }

    const fetchTransactions = async (cursor?: string) => {
      if (!address.value) return
      const params = { size: PAGE_SIZE, address: address.value, cursor }
      const data = await firstValueFrom(radix.ledger.transactionHistory(params))

      transactionHistory.value = data

      transactionHistoryIsValid.value = true
      if (data.cursor && data.transactions.length === PAGE_SIZE) {
        canGoNext.value = true
      } else {
        canGoNext.value = false
      }
    }

    const fieldsFor = (action: ExecutedAction) => {
      const transferAction = action as ExecutedTransferTokensAction
      const stakeAction = action as ExecutedStakeTokensAction
      const unstakeAction = action as ExecutedUnstakeTokensAction
      switch (action.type) {
        case 'TokenTransfer':
          return {
            amount: asBigNumber(transferAction.amount),
            toAccount: transferAction.to_account.toString(),
            fromAccount: transferAction.from_account.toString(),
            rri: transferAction.rri.name
          }
        case 'StakeTokens':
          return {
            amount: asBigNumber(stakeAction.amount),
            validator: stakeAction.to_validator.toString(),
            fromAccount: stakeAction.from_account.toString(),
            rri: stakeAction.rri.name
          }
        case 'UnstakeTokens':
          return {
            amount: asBigNumber(stakeAction.amount),
            validator: unstakeAction.from_validator.toString(),
            toAccount: unstakeAction.to_account.toString(),
            rri: stakeAction.rri.name
          }
        case 'Other':
          return {}
      }
    }

    // takes in 2 dates to filter transactions based on sentAt property
    const handleExportTransactions = async (minDate: Date, maxDate: Date) => {
      showLoader.value = true
      const allTransactions = await fetchAllTransactions(minDate)
      const columnsForCSV = {
        columns: ['sentAt', 'txId', 'fee', 'message', 'status', 'type', 'rri', 'toAccount', 'fromAccount', 'validator', 'amount']
      }

      if (allTransactions) {
        // filter by both the date range as well as look inside any airdrop transactions that dont include the accountId
        const filteredTransactions = allTransactions.filter(transaction => {
          const { sentAt } = transaction
          return sentAt >= minDate && sentAt <= maxDate
        }).map(transaction => ({
          ...transaction,
          actions: transaction.actions.filter(action => {
            const { type } = action
            const transferAction = action as ExecutedTransferTokensAction
            const stakeAction = action as ExecutedStakeTokensAction
            const unstakeAction = action as ExecutedUnstakeTokensAction
            switch (type) {
              case 'TokenTransfer':
                return transferAction.to_account === props.accountId || transferAction.from_account === props.accountId
              case 'StakeTokens':
                return stakeAction.from_account === props.accountId || stakeAction.to_validator === props.accountId
              case 'UnstakeTokens':
                return unstakeAction.from_validator === props.accountId || unstakeAction.to_account === props.accountId
            }
          })
        })).filter(transaction => transaction.actions.length > 0)

        if (!filteredTransactions.length) {
          showLoader.value = false
          showDateError.value = 'empty'
          fromDate.value = ''
          toDate.value = ''
          showDisabledSVG.value = true
          return
        }

        showDateError.value = ''
        const dataForCsv = filteredTransactions.flatMap(transaction => {
          const common = {
            sentAt: transaction.sentAt,
            txId: transaction.txID,
            fee: asBigNumber(transaction.fee),
            message: transaction.message,
            status: transaction.status
          }

          return transaction.actions.map((action) => {
            const extra = fieldsFor(action)
            return { ...common, type: action.type, ...extra }
          })
        })
        const csvConfig = PapaParse.unparse(dataForCsv, columnsForCSV)

        // download csv
        const hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvConfig)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'transactions.csv'
        hiddenElement.click()
        showExportSuccess.value = true
        setTimeout(() => {
          showExportSuccess.value = false
          showLoader.value = false
          toDate.value = ''
          fromDate.value = ''
          showDisabledSVG.value = true
          toggleExportDropdown()
        }, 4000)
      }
    }

    // get all user transactions
    const fetchAllTransactions = async (minDate: Date): Promise<SimpleExecutedTransaction[]> => {
      const allCursorStack: string[] = []
      const allFetchedTransactions = []
      let latestPage = []

      if (!address.value) return []
      const params = { size: PAGE_SIZE, address: address.value }
      const data = await firstValueFrom(radix.ledger.transactionHistory(params))
      latestPage = data.transactions
      allFetchedTransactions.push(...data.transactions)
      allCursorStack.push(data.cursor)
      // iterate over data returned from fetch transactions to get all transactions
      while (latestPage.length === PAGE_SIZE) {
        const nextParams: TransactionHistoryRequestInput = { size: PAGE_SIZE, address: address.value, cursor: allCursorStack[allCursorStack.length - 1] }
        const nextData = await firstValueFrom(radix.ledger.transactionHistory(nextParams))
        latestPage = nextData.transactions
        allFetchedTransactions.push(...nextData.transactions)
        allCursorStack.push(nextData.cursor)
        const shouldEnd = nextData.transactions.some((transaction: SimpleExecutedTransaction) => {
          const { sentAt } = transaction
          return sentAt <= minDate
        })
        if (shouldEnd) break
      }

      return allFetchedTransactions
    }

    try {
      fetchDataForAccount(props.accountId).then(() => {
        fetchTransactions()
      })
    } catch (e) {
      router.push({ path: '/404', query: { term: props.accountId } })
    }

    watch(() => props.accountId, (newVal) => {
      tokenBalanceIsValid.value = false
      transactionHistoryIsValid.value = false
      fetchDataForAccount(newVal).then(() => {
        fetchTransactions()
      })
    })

    const handleNextPage = () => {
      transactionHistoryIsValid.value = false
      if (!transactionHistory.value) return
      cursorStack.value.push(transactionHistory.value.cursor)
      fetchTransactions(cursorStack.value[cursorStack.value.length - 1])
    }

    const handlePreviousPage = () => {
      transactionHistoryIsValid.value = false
      cursorStack.value.pop()
      fetchTransactions(cursorStack.value.length > 0 ? cursorStack.value[cursorStack.value.length - 1] : '')
    }

    const loading = computed(() => {
      return (!tokenBalanceIsValid.value || !transactionHistoryIsValid.value) && !!address.value
    })

    const tokenBalanceFor = (token: Token) => {
      if (!tokenBalances.value) return null
      return tokenBalances.value.account_balances.liquid_balances.find((lb) => lb.token_identifier.rri.equals(token.rri)) || null
    }

    const totalXRD: ComputedRef<AmountT> = computed(() => {
      if (!nativeToken.value || !tokenBalances.value) return zero
      const nativeTokenBalance = tokenBalanceFor(nativeToken.value)
      if (!nativeTokenBalance) return zero
      return nativeTokenBalance.value
    })

    const totalStakedAndUnstaked: ComputedRef<AmountT> = computed(() => {
      if (!tokenBalances.value) return zero
      return tokenBalances.value.account_balances.staked_and_unstaking_balance.value || zero
    })

    const availablePlusStakedAndUnstakedXRD: ComputedRef<AmountT> = computed(() => {
      return add(totalXRD.value, totalStakedAndUnstaked.value)
    })

    const otherTokenBalances: ComputedRef<Decoded.TokenAmount[]> = computed(() => {
      if (!tokenBalances.value || !nativeToken.value) return []

      const data = tokenBalances.value.account_balances.liquid_balances.filter((tb) => {
        return !tb.token_identifier.rri.equals(nativeToken.value!.rri)
      })

      return data
    })

    const toggleExportDropdown = (): void => {
      showExportDropdown.value = !showExportDropdown.value
    }

    const toggleCustomExportButtonEnabled = (): boolean => {
      showDateError.value = ''
      if (toDate.value && fromDate.value) {
        showDisabledSVG.value = false
        return true
      } else {
        showDisabledSVG.value = true
        return false
      }
    }

    const handleTypeOfExport = (type: string) => {
      showDateError.value = ''
      const todaysDate = new Date()
      if (type === 'week') {
        const lastWeekDate = subWeeks(todaysDate, 1)
        handleExportTransactions(lastWeekDate, todaysDate)
      } else if (type === 'month') {
        const lastMonthDate = subMonths(todaysDate, 1)
        handleExportTransactions(lastMonthDate, todaysDate)
      } else if (type === 'year') {
        const lastYearDate = subYears(todaysDate, 1)
        handleExportTransactions(lastYearDate, todaysDate)
      } else if (type === 'custom') {
        if (checkForValidDates()) {
          const convertedFromDate = parse(fromDate.value, 'yyyy-MM-dd', new Date())
          const convertedToDate = parse(toDate.value, 'yyyy-MM-dd', new Date())
          // if dates are equal make one at 1200AM and the other at 11:59pm
          if (isEqual(convertedFromDate, convertedToDate)) {
            convertedToDate.setHours(23, 59, 59)
          }
          handleExportTransactions(convertedFromDate, convertedToDate)
        } else {
          showDateError.value = 'date'
        }
      }
    }

    const checkForValidDates = (): boolean => {
      const currentDate = new Date()
      const convertedFromDate = parse(fromDate.value, 'yyyy-MM-dd', new Date())
      const convertedToDate = parse(toDate.value, 'yyyy-MM-dd', new Date())
      // if first date is after second
      // if first date is after current date
      // if second date is after current date
      const a = isAfter(convertedFromDate, convertedToDate)
      const b = isAfter(convertedFromDate, currentDate)
      const c = isAfter(convertedToDate, currentDate)
      if (a || b || c) {
        showDateError.value = 'date'
        return false
      } else {
        showDateError.value = ''
        return true
      }
    }

    return {
      accountError,
      activeStakes,
      activeUnstakes,
      address,
      canGoNext,
      cursorStack,
      loading,
      nativeToken,
      tokenBalanceIsValid,
      tokenBalances,
      transactionError,
      transactionHistory,
      transactionHistoryIsValid,
      totalXRD,
      totalStakedAndUnstaked,
      availablePlusStakedAndUnstakedXRD,
      otherTokenBalances,
      handleNextPage,
      handlePreviousPage,
      handleExportTransactions,
      showExportDropdown,
      showExportSuccess,
      showCustomExportButtonEnabled,
      toggleExportDropdown,
      toggleCustomExportButtonEnabled,
      handleTypeOfExport,
      showDisabledSVG,
      showDateError,
      showLoader,
      toDate,
      fromDate
    }
  }
})
