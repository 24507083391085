
import { defineComponent } from 'vue'
import SearchField from '@/components/SearchField.vue'
import useRadix from '@/composables/useRadix'
import { Network } from '@radixdlt/application'

export default defineComponent({
  name: 'NavBar',
  components: {
    SearchField
  },
  setup () {
    const { network } = useRadix()
    const value = network.value

    const isStokenet = value === Network.STOKENET
    return { isStokenet }
  }
})
