<template>
  <dl class="border border-rGray rounded-md bg-white mb-5 divide-y divide-gray-200">
    <div class="p-5 flex flex-col gap-2">
      <dt class="text-sm font-light text-rGrayDarkerer text-left">
        {{ $t('transaction.typeLabel') }}
      </dt>
      <dd class="font-light text-sm text-rBlack mt-0 col-span-3 inline-flex items-center">
        {{ action.type  }}
      </dd>
    </div>
  </dl>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ExecutedOtherAction } from '@radixdlt/application'

const ActionExpandedListItemOther = defineComponent({
  props: {
    action: {
      type: Object as PropType<ExecutedOtherAction>,
      required: true
    }
  }
})

export default ActionExpandedListItemOther
</script>
