
import { defineComponent, ref } from 'vue'
import {
  safelyUnwrapAddress,
  safelyUnwrapRRI,
  safelyUnwrapValidator
} from '@/helpers/validateRadixTypes'
import { TransactionIdentifier } from '@radixdlt/application'
import useRadix from '@/composables/useRadix'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const hasError = ref(false)
    const term = ref('')
    const { network } = useRadix()
    const router = useRouter()

    return {
      network,
      hasError,
      term,
      handleSubmit () {
        const trimmedTerm = term.value.trim()
        const accountAddress = safelyUnwrapAddress(trimmedTerm)
        const validatorAddress = safelyUnwrapValidator(trimmedTerm)
        const rri = safelyUnwrapRRI(trimmedTerm)
        if (accountAddress && accountAddress.network === network.value) {
          router.push({ path: `/accounts/${trimmedTerm}` })
          term.value = ''
          hasError.value = false
          return
        } else if (validatorAddress && validatorAddress.network === network.value) {
          router.push({ path: `/validators/${trimmedTerm}` })
          term.value = ''
          hasError.value = false
          return
        } else if (rri) {
          router.push({ path: `/tokens/${trimmedTerm}` })
          term.value = ''
          hasError.value = false
          return
        }
        try {
          const identifier = TransactionIdentifier.create(trimmedTerm)
          if (identifier.isOk()) {
            router.push({ path: `/transactions/${trimmedTerm}` })
            term.value = ''
            hasError.value = false
            return
          }
        } catch {
          // Error thrown while creating transaction identifier
        }
        hasError.value = true
        router.push({ path: '/404', query: { term: trimmedTerm } })
      }
    }
  }
})
