<template>
  <div class="border border-rGray rounded-md flex flex-row mb-2 overflow-visible">
    <div class="bg-rGrayLightest text-rGrayDark text-sm pl-3 w-44 flex flex-col space-y-2 justify-center items-start">
      <div class="inline-flex">
        <router-link :to="transactionAddress" class="text-rBlue font-mono">
          {{shortenedTransactionID}}
        </router-link>
        <click-to-copy :text="transactionID" />
      </div>
      <div>{{ sentAt }}</div>
    </div>
    <div class="flex-1">
      <div class="py-3.5 px-3">
        <div v-for="(action, i) in relatedActions" :key="i">
          <action-list-item-stake-tokens
            v-if="action.type === 'StakeTokens'"
            :action="action"
            :index="i"
          />
          <action-list-item-unstake-tokens
            v-else-if="action.type === 'UnstakeTokens'"
            :action="action"
            :index="i"
          />
          <action-list-item-transfer-tokens
            v-else-if="action.type === 'TokenTransfer'"
            :action="action"
            :activeAddress=activeAddress
            :index="i"
          />
          <action-list-item-other
            v-else
            :action="action"
            :index="i"
          />
        </div>
      </div>
      <div v-if="decodedMessage" class="text-sm px-3 py-2 bg-rGrayLightest bg-opacity-60 flex items-center">
        <div class="h-5 w-6 flex items-center justify-center -ml-1 mr-1">
          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5" v-if="isEncrypted">
            <path d="M2.04883 4.93512V3.79987C2.04883 2.25355 3.30238 1 4.8487 1C6.39502 1 7.64857 2.25355 7.64857 3.79987V4.93512" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M1 11.4001V4.84473H2.13447H7.58739H8.72185V11.0556H2.60558" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
          </svg>

          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5" v-else>
            <path d="M15 5H5V11.1288H10.9545L13.3094 13.3889V11.1288H15V5Z" stroke="#7A99AC" stroke-miterlimit="10"/>
            <line x1="6.2002" y1="7" x2="13.7002" y2="7" stroke="#7A99AC"/>
            <line x1="6.2002" y1="9" x2="13.7002" y2="9" stroke="#7A99AC"/>
          </svg>
        </div>

        <transaction-message v-if="transaction.message" :message="transaction.message" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ExecutedTransaction, ExecutedAction, ActionType } from '@radixdlt/application'
import { DateTime } from 'luxon'
import ActionListItemStakeTokens from '@/components/ActionListItemStakeTokens.vue'
import ActionListItemUnstakeTokens from '@/components/ActionListItemUnstakeTokens.vue'
import ActionListItemTransferTokens from '@/components/ActionListItemTransferTokens.vue'
import ActionListItemOther from '@/components/ActionListItemOther.vue'
import TransactionMessage from './TransactionMessage.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import { AccountAddressT } from '@radixdlt/account'
import { formatTransactionAddressForDisplay } from '@/helpers/formatter'
import { decodeMessage, isEncrypted } from '@/helpers/message'

export default defineComponent({
  components: {
    ActionListItemStakeTokens,
    ActionListItemTransferTokens,
    ActionListItemUnstakeTokens,
    ActionListItemOther,
    ClickToCopy,
    TransactionMessage
  },

  props: {
    transaction: {
      type: Object as PropType<ExecutedTransaction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeAddress: {
      type: Object as PropType<AccountAddressT>,
      required: false
    }
  },

  computed: {
    sentAt (): string {
      return DateTime.fromJSDate(this.transaction.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
    },

    transactionAddress (): string {
      return `/transactions/${this.transaction.txID.toString()}`
    },

    transactionID () : string {
      return this.transaction.txID.toString()
    },

    shortenedTransactionID (): string {
      return formatTransactionAddressForDisplay(this.transaction.txID)
    },

    relatedActions (): ExecutedAction[] {
      if (!this.activeAddress) return this.transaction.actions

      return this.transaction.actions.filter((action: ExecutedAction) => {
        let related
        switch (action.type) {
          case ActionType.TOKEN_TRANSFER:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.to_account ||
                this.activeAddress.toString() === action.from_account
              : false
            break
          case ActionType.STAKE_TOKENS:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.from_account
              : false
            break
          case ActionType.UNSTAKE_TOKENS:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.to_account
              : false
            break
          case ActionType.OTHER:
            related = false
            break
        }
        return related
      })
    },

    decodedMessage (): string | false | null {
      if (!this.transaction.message) return null
      return decodeMessage(this.transaction.message)
    },

    isEncrypted (): boolean {
      if (!this.transaction.message) return false
      return isEncrypted(this.transaction.message)
    }
  }
})
</script>
