<template>
  <span class="relative group cursor-pointer">
    <span v-if="overrideDisplayNumber" @click.stop="copyText">{{ overrideDisplayNumber }}</span>
    <span v-else @click.stop="copyText">{{ displayNumber }}</span>
    <div class="absolute z-40 invisible group-hover:visible -mt-full bg-rGrayLightest text-rBlack bottom-full  text-xs p-1 rounded-sm shadow border border-solid border-rGrayLight">
      {{ fullNumber }}
    </div>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AmountT } from '@radixdlt/application'
import BigNumber from 'bignumber.js'

BigNumber.set({
  ROUNDING_MODE: BigNumber.ROUND_FLOOR,
  EXPONENTIAL_AT: [-30, 30]
})

const numberFormatUSA = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: ''
}

// internal format used so BigNumber can read in its own output
const internalFormat = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 0,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
  suffix: ''
}

const formattBigNumber = (x: BigNumber, showFull = false, format: BigNumber.Format = numberFormatUSA) => {
  /*
  1000000000000.59 => 1,000,000,000,000.5
  1000000000000 => 1,000,000,000,000
  1000.153229521 => 1,000.15322952
  1000.500 => 1,000.5
  0.109351852001 => 0.10935185200
  0.10935185200 => 0.109351852
  */

  // Scenario where we're wanting to just show the full number properly formatted
  if (showFull === true) {
    var internallyFormatted = x.toFormat(internalFormat)
    const z = new BigNumber(internallyFormatted)
    return z.toFormat(format)
  }

  const maxPlaces = 12
  const integerPart = x.integerValue()
  const decimalPart = x.minus(integerPart)
  const dpLength = decimalPart.toFixed().length
  const ipLength = integerPart.toFixed().length

  if (decimalPart.isZero()) {
    return x.toFormat()
  } else if (integerPart.isZero()) {
    if (dpLength > maxPlaces) {
      return x.toFormat(maxPlaces)
    }
  } else {
    if (ipLength >= maxPlaces) {
      return x.toFormat(1)
    }
    const totalPlaces = (dpLength - 2) + ipLength
    if (totalPlaces > maxPlaces) {
      return x.toFormat(maxPlaces - ipLength)
    }
  }
  return x.toFormat()
}

export const asBigNumber = (amount: AmountT, showFull = false, format = true): string => {
  const bigNumber = new BigNumber(amount.toString())
  const shiftedAmount = bigNumber.shiftedBy(-18) // Atto

  // Catch for Transaction specific page.
  if (showFull === false) {
    return format ? formattBigNumber(shiftedAmount) : shiftedAmount.toString()
  }
  // showFull is only for overriding w/ a specific display value.
  return formattBigNumber(shiftedAmount, showFull)
}

const BigAmount = defineComponent({
  props: {
    amount: {
      type: Object as PropType<AmountT>,
      required: true
    },

    format: {
      type: Boolean,
      required: false,
      default: true
    },

    overrideDisplayNumber: {
      type: String,
      required: false
    }
  },

  computed: {
    asBigNumber (): string {
      return asBigNumber(this.amount, false, this.format)
    },

    displayNumber (): string {
      return asBigNumber(this.amount, false)
    },

    fullNumber (): string {
      return asBigNumber(this.amount, true)
    }
  },

  methods: {
    copyText () {
      const value = asBigNumber(this.amount, true).replaceAll(',', '')
      navigator.clipboard.writeText(value.toString())
    }
  }
})

export default BigAmount
</script>
