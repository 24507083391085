
import { defineComponent } from 'vue'
import { getRRIExplorerUrl } from '@/helpers/urlHelpers'

const TokenSymbol = defineComponent({
  props: {
    hasGreyBackground: {
      type: Boolean,
      required: false
    },
    rri: {
      type: String,
      required: true
    },
    symbol: {
      type: String,
      required: true
    }
  },

  computed: {
    rriUrl (): string {
      return getRRIExplorerUrl(this.rri)
    }
  }
})

export default TokenSymbol
