
import { defineComponent, PropType } from 'vue'
import { ExecutedTransaction, ExecutedAction, ActionType } from '@radixdlt/application'
import { DateTime } from 'luxon'
import ActionListItemStakeTokens from '@/components/ActionListItemStakeTokens.vue'
import ActionListItemUnstakeTokens from '@/components/ActionListItemUnstakeTokens.vue'
import ActionListItemTransferTokens from '@/components/ActionListItemTransferTokens.vue'
import ActionListItemOther from '@/components/ActionListItemOther.vue'
import TransactionMessage from './TransactionMessage.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import { AccountAddressT } from '@radixdlt/account'
import { formatTransactionAddressForDisplay } from '@/helpers/formatter'
import { decodeMessage, isEncrypted } from '@/helpers/message'

export default defineComponent({
  components: {
    ActionListItemStakeTokens,
    ActionListItemTransferTokens,
    ActionListItemUnstakeTokens,
    ActionListItemOther,
    ClickToCopy,
    TransactionMessage
  },

  props: {
    transaction: {
      type: Object as PropType<ExecutedTransaction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeAddress: {
      type: Object as PropType<AccountAddressT>,
      required: false
    }
  },

  computed: {
    sentAt (): string {
      return DateTime.fromJSDate(this.transaction.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
    },

    transactionAddress (): string {
      return `/transactions/${this.transaction.txID.toString()}`
    },

    transactionID () : string {
      return this.transaction.txID.toString()
    },

    shortenedTransactionID (): string {
      return formatTransactionAddressForDisplay(this.transaction.txID)
    },

    relatedActions (): ExecutedAction[] {
      if (!this.activeAddress) return this.transaction.actions

      return this.transaction.actions.filter((action: ExecutedAction) => {
        let related
        switch (action.type) {
          case ActionType.TOKEN_TRANSFER:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.to_account ||
                this.activeAddress.toString() === action.from_account
              : false
            break
          case ActionType.STAKE_TOKENS:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.from_account
              : false
            break
          case ActionType.UNSTAKE_TOKENS:
            related = this.activeAddress
              ? this.activeAddress.toString() === action.to_account
              : false
            break
          case ActionType.OTHER:
            related = false
            break
        }
        return related
      })
    },

    decodedMessage (): string | false | null {
      if (!this.transaction.message) return null
      return decodeMessage(this.transaction.message)
    },

    isEncrypted (): boolean {
      if (!this.transaction.message) return false
      return isEncrypted(this.transaction.message)
    }
  }
})
