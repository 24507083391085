<template>
  <template v-if="error">
    {{ $t('error.default') }}
  </template>
  <template v-else-if="!resourceIdentifier">
    <div class="flex justify-center py-10">
      <loading-icon class="text-rGrayDark" />
    </div>
  </template>
  <template v-else>
    <div class="max-w-4xl mx-auto py-6 px-5">
      <h1 class="my-5 flex justify-between">
        <div>
          <span class="text-rGrayDark pr-5">RRI:</span>
          <span class="text-rGrayDarkest font-medium truncate font-mono"> {{ resourceIdentifier.rri.toString() }}</span>
        </div>
        <div class="flex text-rGrayDark text-xs items-center">
          <click-to-copy :text="resourceIdentifier.rri.toString()">
            <span>{{ $t('wallet.copyAddress') }}</span>
          </click-to-copy>
        </div>
      </h1>
      <div class="bg-rOffwhite border border-rGray rounded-md mb-5">
        <dl class="divide-y divide-gray-200">

          <div class="py-5 grid grid-cols-4 gap-4">
            <dt class="text-sm font-light text-rGrayDarker flex flex-row
            items-center justify-end">
              {{ $t('resourceIdentifier.symbol') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3 text-2xl
            font-thin uppercase">
              {{ resourceIdentifier.symbol }}
            </dd>
          </div>

          <div class="py-5 grid grid-cols-4 gap-4">
            <dt class="text-sm font-light text-rGrayDarker text-right">
              {{ $t('resourceIdentifier.name') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3 inline-flex items-center overflow-x-auto">
              {{ resourceIdentifier.name }}
            </dd>
          </div>

          <div class="py-5 grid grid-cols-4 gap-4">
            <dt class="text-sm font-light text-rGrayDarker text-right">
              {{ $t('resourceIdentifier.description') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3 inline-flex items-center overflow-x-auto">
              {{ resourceIdentifier.description }}
            </dd>
          </div>
          <div class="py-5 grid grid-cols-4 gap-4">
            <dt class="text-sm font-light text-rGrayDarker text-right">
              {{ $t('resourceIdentifier.url') }}
            </dt>

            <dd class="font-light text-sm text-rBlack mt-0 col-span-3 inline-flex items-center overflow-x-auto">
              <a v-if="resourceIdentifier.tokenInfoURL"
              :href="resourceIdentifier.tokenInfoURL" target="__blank"
              class="text-rBlue flex flex-row items-center">
                {{resourceIdentifier.tokenInfoURL.toString()}}

                <svg width="13" height="13" viewBox="0 0 14 14" fill="none"
                xmlns="http://www.w3.org/2000/svg" class="ml-2">
                  <path d="M3.66797 3.60429H10.9101V10.8464" stroke="#7A99AC" stroke-miterlimit="10"/>
                  <path d="M10.9096 3.60429L3.60449 10.9095" stroke="#7A99AC" stroke-miterlimit="10"/>
                </svg>
              </a>
              <span v-else> - </span>
            </dd>
          </div>

          <div class="py-5 grid grid-cols-4 gap-4">
            <dt class="text-sm font-light text-rGrayDarker flex flex-row
            items-center justify-end">
              {{ $t('resourceIdentifier.supply') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3 flex
            flex-row items-center">
              <big-amount :amount="resourceIdentifier.currentSupply"
              class="text-2xl mr-4 font-thin" />
              <token-symbol
                :hasGreyBackground="true"
                :symbol="resourceIdentifier.symbol"
                :rri="resourceIdentifier.rri.toString()"
              >
              </token-symbol>
            </dd>
          </div>

          <div class="py-5 grid grid-cols-4 gap-4">
            <dt class="text-sm font-light text-rGrayDarker flex flex-row
            items-center justify-end">
              {{ $t('resourceIdentifier.mutable') }}
            </dt>
            <dd class="">
              <div v-if="resourceIdentifier.isSupplyMutable">
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 flex-grow-0  ">
                  <circle cx="7" cy="7" r="6.5" transform="rotate(90 7 7)" fill="#00C389" stroke="#00C389"/>
                  <path d="M6.00061 10.4128L3.5 7.91205L4.24235 7.16963L5.94181 8.86888L9.94665 4.19995L10.7437 4.88357L6.00061 10.4128Z" fill="white"/>
                </svg>
              </div>
              <div v-else>
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 flex-grow-0">
                  <circle cx="7" cy="7" r="6.5" transform="rotate(90 7 7)" fill="#EF4136" stroke="#EF4136"/>
                  <rect x="4" y="5" width="1" height="7" transform="rotate(-45 4 5)" fill="white"/>
                  <rect x="8.94977" y="4.29285" width="1" height="7" transform="rotate(45 8.94977 4.29285)" fill="white"/>
                </svg>
              </div>
            </dd>
          </div>

        </dl>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, Ref, watch, ref } from 'vue'
import { ResourceIdentifier } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import { useRouter } from 'vue-router'
import BigAmount from '@/components/BigAmount.vue'
import TokenSymbol from '@/components/TokenSymbol.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import useRadix from '@/composables/useRadix'
import Observed from '@/helpers/observed'

export default defineComponent({
  components: {
    LoadingIcon,
    BigAmount,
    ClickToCopy,
    TokenSymbol
  },

  props: {
    rri: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const error: Ref<Error | null> = ref(null)
    const router = useRouter()
    const { radix } = useRadix()
    const resourceIdentifier: Ref<Observed<ReturnType<typeof radix.ledger.tokenInfo>> | null> = ref(null)

    const fetchTokenInfo = async (id: string) => {
      try {
        const initialIdentifier = ResourceIdentifier.fromUnsafe(id.toString())
        if (initialIdentifier.isErr()) {
          throw Error('Invalid RRI')
        }
        resourceIdentifier.value = await firstValueFrom(radix.ledger.tokenInfo(initialIdentifier.value))
      } catch (e) {
        error.value = e
        router.push({ path: '/404', query: { term: props.rri } })
      }
    }

    fetchTokenInfo(props.rri)

    watch(() => props.rri, (newId) => {
      resourceIdentifier.value = null
      fetchTokenInfo(newId.toString())
    })

    return { resourceIdentifier, error }
  }
})
</script>
