<template>
  <div class="flex flex-row text-rGrayMed">
    <div class="md:flex flex-row flex-1 items-center">
      <div v-if="isRecipient" class="flex flex-row items-center w-24">
        <img src="@/assets/receiveTokens.svg" alt="receive tokens" />
        <span class="ml-2 text-sm">{{ $t('history.receivedAction') }}</span>
      </div>
      <div v-if="!isRecipient" class="flex flex-row items-center w-24">
        <img src="@/assets/sendTokens.svg" alt="send tokens" />
        <span class="ml-2 text-sm">{{ $t('history.sentAction') }}</span>
      </div>
      <div>
        <big-amount :amount="action.amount" class="text-rBlack" />
        <token-symbol
          :hasGreyBackground="false"
          :symbol="action.rri.name.toUpperCase()"
          :rri="action.rri.toString()"
        >
        </token-symbol>
      </div>
    </div>
    <!-- desktop -->
    <div class="hidden md:flex flex-col items-end text-sm">
      <div v-if="!isRecipient" class="flex flex-row flex-1 min-w-0">
        <span>{{ $t('history.toLabel') }}:</span>
        <router-link
          :to="toAddress"
          class="text-rBlue ml-2 min-w-0 w-32 font-mono"
          >{{ shortenedAddress(action.to_account) }}</router-link
        >
        <click-to-copy :text="action.to_account.toString()" />
      </div>
      <div v-if="isRecipient" class="flex flex-row flex-1 min-w-0">
        <span>{{ $t('history.fromLabel') }}:</span>
        <router-link
          :to="fromAddress"
          class="text-rBlue ml-2 min-w-0 w-32 font-mono"
          >{{ shortenedAddress(action.from_account) }}</router-link
        >
        <click-to-copy :text="action.from_account.toString()" />
      </div>
    </div>
    <!-- mobile -->
    <div class="md:hidden flex flex-col items-end text-sm">
      <div
        v-if="!isRecipient"
        class="md:hidden min-w-0 inline text-left items-center text-sm"
      >
        <span>{{ $t('history.toLabel') }}:</span>
        <br />
        <div class="flex">
          <router-link
            :to="toAddress"
            class="text-rBlue min-w-0 w-32 pt-1 font-mono"
            >{{ shortenedAddress(action.to_account) }}</router-link
          >
          <click-to-copy :text="action.to_account.toString()" />
        </div>
      </div>
      <div
        v-if="isRecipient"
        class="md:hidden min-w-0 inline text-left items-center text-sm"
      >
        <span>{{ $t('history.fromLabel') }}:</span>
        <br />
        <div class="flex">
          <router-link
            :to="fromAddress"
            class="text-rBlue min-w-0 w-32 pt-1 font-mono"
            >{{ shortenedAddress(action.from_account) }}</router-link
          >
          <click-to-copy :text="action.from_account.toString()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ExecutedTransferTokensAction } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import { AccountAddressT } from '@radixdlt/account'
import { formatWalletAddressForDisplay } from '@/helpers/formatter'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionListItemTransferTokens = defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedTransferTokensAction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeAddress: {
      type: Object as PropType<AccountAddressT>,
      required: false
    }
  },

  computed: {
    toAddress (): string {
      return `/accounts/${this.action.to_account.toString()}`
    },

    isRecipient (): boolean {
      if (!this.activeAddress) return false
      return this.action.to_account.toString() === this.activeAddress.toString()
    },

    fromAddress (): string {
      return `/accounts/${this.action.from_account.toString()}`
    }
  },

  methods: {
    shortenedAddress (address: AccountAddressT): string {
      return formatWalletAddressForDisplay(address)
    }
  }
})

export default ActionListItemTransferTokens
</script>
