<template>
  <div v-if="isLoading" class="flex justify-center my-12">
    <loading-icon class="text-rGrayDark" />
  </div>

  <div v-else class="flex flex-col max-w-5xl mx-auto py-8 overflow-x-scroll">
    <div v-if="showTestnetWarning" class="text-lg font-bold bg-rBlue text-white text-center mb-12 border border-rBlue rounded-md p-2 py-4 w-full">
      {{ $t('validators.betanetWarning') }}
    </div>
    <div class="">
      <div class="pt-3 pb-2 align-middle inline-block px-4">
        <table class="">
          <thead class="bg-rBlue border-l border-r border-rBlue border-solid md:h-16 hidden md:table-row">
            <th class="py-1 text-xs font-medium text-center text-white">
              <Tooltip textKey="validators.validatorTooltip" :leftmost="true" class="cursor-pointer px-4">
                {{ $t('validators.validatorLabel') }}
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.stakeTooltip" class="cursor-pointer px-4">
                {{ $t('validators.stakeLabel') }}
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.adsTooltip" class="cursor-pointer md:px-2">
                {{ $t('validators.adsLabel') }}
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.ownerStakeTooltip" class="cursor-pointer px-4">
                {{ $t('validators.ownerStakeLabel') }}
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.feePercentTooltip" class="cursor-pointer px-4">
                {{ $t('validators.feePercentLabel') }}
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.recentUptimeTooltip" class="cursor-pointer px-4">
                <span class="whitespace-nowrap">{{ $t('validators.recentUptimeLabel') }}</span>
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.addressTooltip" class="cursor-pointer px-4">
                {{ $t('validators.addressLabel') }}
              </Tooltip>
            </th>
            <th class="py-1 text-xs font-medium text-white text-center">
              <Tooltip textKey="validators.websiteTooltip" :rightmost="true" class="cursor-pointer px-2">
                {{ $t('validators.websiteLabel') }}
              </Tooltip>
            </th>
          </thead>
          <tbody>
          <validator-list-item
            v-for="({ validator, totalStake }, i) in fivePercentStakeValidators"
            :key="i"
            :validator="validator"
            :percentStaked="totalStake"
          />

          <tr v-if="fivePercentStakeValidators.length > 0" class="h-16 text-center border-l
          border-solid border-yellow-600">
            <td colspan="1" class="bg-yellow-600 border-b-4 border-l-0
            border-solid border-yellow-500
            font-bold text-white">
              <div class="flex flex-row items-center justify-center">
                <IconArrow class="transform rotate-180" />
                <span class="px-2">{{ $t('validators.notPreferred') }}</span>
                <IconArrow class="transform rotate-180" />
              </div>
            </td>
            <td class='py-4 bg-rBlack text-white text-center h-16 divide-y
            text-sm border-b-4 border-solid border-rBlack' colspan="5">
              The above validators already have a high amount of stake.<span class="font-bold"> It is strongly recommended to stake to validators below this line.</span> Click <a href="https://learn.radixdlt.com/article/how-should-i-choose-validators-to-stake-to" class="border-none underline"> here</a> for more information on responsible staking.
            </td>
            <td class="bg-rRedMuted border-b-4 border-solid border-rRed
            font-bold text-white text-center" colspan="2">
              <div class="flex flex-row items-center justify-center">
                <IconArrow />
                <span class="px-2">{{ $t('validators.doNotStake') }}</span>
                <IconArrow />
              </div>
            </td>
          </tr>

          <validator-list-item
            v-for="({ validator, totalStake }, i) in threePercentStakeValidators"
            :key="i"
            :validator="validator"
            :percentStaked="totalStake"
          />

          <tr v-if="threePercentStakeValidators.length > 0" class="h-16 text-center border-l
          border-solid md:border-rGreenMuted  md:hidden mb-4">
          <!-- mobile variant -->
            <span class="block bg-yellow-600 font-bold text-white text-center py-2 border-b-4 border-solid border-yellow-500">
              <div class="flex flex-row items-center justify-center">
                <IconArrow />
                <span class="px-12">{{ $t('validators.notPreferred') }}</span>
                <IconArrow />
              </div>
            </span>
            <span class="block bg-rDarkBlue text-white py-4 px-10 text-sm">
              The above validators already have a medium amount of stake.
              <span class="font-bold">
                It is preferred to stake to validators below this line.
              </span> Click <a href="https://learn.radixdlt.com/article/how-should-i-choose-validators-to-stake-to" class="border-none underline"> here</a>
               for more information on responsible staking.
            </span>
            <span class="block bg-rGreenMuted font-bold text-white py-2 border-t-4 border-l-0 border-solid border-rGreen">
                <div class="flex flex-row items-center justify-center">
                <IconArrow class="transform rotate-180" />
                <span class="px-20">Stake</span>
                <IconArrow class="transform rotate-180" />
              </div>
            </span>
          </tr>
          <!-- desktop variant -->
          <tr v-if="threePercentStakeValidators.length > 0" class="h-16 text-center border-l
          border-solid border-rGreenMuted hidden md:table-row">
            <td colspan="1" class="bg-rGreenMuted border-b-4 border-l-0 border-solid border-rGreen
            font-bold text-white">
              <div class="flex flex-row items-center justify-center">
                <IconArrow class="transform rotate-180" />
                <span class="px-2">Stake</span>
                <IconArrow class="transform rotate-180" />
              </div>
            </td>
            <td class='py-4 bg-rBlack text-white text-center h-16 divide-y
            text-sm border-b-4 border-solid border-rBlack' colspan="5">
              The above validators already have a medium amount of stake.<span
              class="font-bold"> It is preferred to stake to validators below this line.</span> Click <a href="https://learn.radixdlt.com/article/how-should-i-choose-validators-to-stake-to" class="border-none underline"> here</a> for more information on responsible staking.
            </td>
            <td class="bg-yellow-600 border-b-4 border-solid border-yellow-500
            font-bold text-white text-center" colspan="2">
              <div class="flex flex-row items-center justify-center">
                <IconArrow />
                <span class="px-2">{{ $t('validators.notPreferred') }}</span>
                <IconArrow />
              </div>
            </td>
          </tr>

          <validator-list-item
            v-for="({ validator, totalStake }, i) in middleValidators"
            :key="i"
            :validator="validator"
            :percentStaked="totalStake"
          />

          <tr class="bg-rOffwhite text-rGrayDark text-center h-12 border-l border-r border-solid border-rGrayLightest">
            <td colspan="8">{{ $t('validators.activeValidatorsEpoch') }}.</td>
          </tr>
          <validator-list-item
            v-for="({ validator }, i) in bottomValidators"
            :key="i"
            :validator="validator"
            :hideUptime="true"
          />
        </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AmountT } from '@radixdlt/application'
import LoadingIcon from '@/components/LoadingIcon.vue'
import ValidatorListItem from '@/components/ValidatorListItem.vue'
import BigNumber from 'bignumber.js'
import Tooltip from '@/components/Tooltip.vue'
import IconArrow from '@/components/IconArrow.vue'
import useRadix from '@/composables/useRadix'
import useValidators from '@/composables/useValidators'

BigNumber.set({
  ROUNDING_MODE: BigNumber.ROUND_FLOOR,
  EXPONENTIAL_AT: [-30, 30]
})

export default defineComponent({
  components: {
    IconArrow,
    LoadingIcon,
    ValidatorListItem,
    Tooltip
  },

  setup () {
    const showTestnetWarning = process.env.VUE_APP_SHOW_TESTNET_WARNINGS === 'true'
    const { radix, network } = useRadix()

    const {
      validators,
      isLoadingValidators: isLoading,
      totalStaked,
      fivePercentStakeValidators,
      threePercentStakeValidators,
      middleValidators,
      bottomValidators,
      fetchValidators
    } = useValidators(radix, network.value)

    fetchValidators()

    return {
      validators,
      isLoading,
      totalStaked,
      fivePercentStakeValidators,
      threePercentStakeValidators,
      middleValidators,
      bottomValidators,
      showTestnetWarning
    }
  },

  methods: {
    stakePercentageLabel (validaterStake: AmountT):string {
      const bigVS = new BigNumber(validaterStake.toString())
      const bigTS = new BigNumber(this.totalStaked.toString())
      return bigVS.dividedBy(bigTS).multipliedBy(100).toFixed(2)
    }
  }
})
</script>
