<template>
  <div class="flex flex-row text-rGrayMed">
    <div class="md:flex flex-row flex-1 min-w-0">
      <div class="flex flex-row items-center w-24">
        <img src="@/assets/stakeTokens.svg" alt="receive tokens" />
        <span class="ml-2 text-sm">{{ $t('history.stakeAction') }}</span>
      </div>
      <div>
        <big-amount :amount="action.amount" class="text-rBlack" />
        <token-symbol
          :hasGreyBackground="false"
          :symbol="action.rri.name.toUpperCase()"
          :rri="action.rri.toString()"
        >
        </token-symbol>
      </div>
    </div>
    <!-- desktop -->
    <div
      class="hidden md:flex flex-row min-w-0 text-left items-center text-sm  "
    >
      <span>{{ $t('history.validatorLabel') }}:</span>
      <router-link
        :to="validatorURL"
        class="text-rBlue ml-2 w-32 min-w-0 font-mono"
        >{{ shortenedAddress(action.to_validator.toString()) }}</router-link
      >
      <click-to-copy :text="action.to_validator.toString()" />
    </div>
    <!-- mobile -->
    <div class="md:hidden min-w-0 inline text-left items-center text-sm">
      <span>{{ $t('history.validatorLabel') }}:</span>
      <br />
      <div class="flex">
        <router-link
          :to="validatorURL"
          class="text-rBlue w-32 min-w-0 font-mono pt-1"
          >{{ shortenedAddress(action.to_validator.toString()) }}</router-link
        >
        <click-to-copy :text="action.to_validator.toString()" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ExecutedStakeTokensAction,
  ValidatorAddressT
} from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import { formatValidatorAddressForDisplay } from '@/helpers/formatter'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionListItemStakeTokens = defineComponent({
  components: {
    ClickToCopy,
    BigAmount,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedStakeTokensAction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  methods: {
    shortenedAddress (address: ValidatorAddressT): string {
      return formatValidatorAddressForDisplay(address)
    }
  },

  computed: {
    validatorURL (): string {
      return `/validators/${this.action.to_validator.toString()}`
    }
  }
})

export default ActionListItemStakeTokens
</script>
