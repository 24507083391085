
import { defineComponent, PropType } from 'vue'
import { ExecutedTransaction } from '@radixdlt/application'
import TransactionListItem from '@/components/TransactionListItem.vue'
import MobileTransactionListItem from '@/components/MobileTransactionListItem.vue'
import { AccountAddressT } from '@radixdlt/account'

export default defineComponent({
  components: {
    TransactionListItem,
    MobileTransactionListItem
  },
  props: {
    transactions: {
      type: Array as PropType<Array<ExecutedTransaction>>,
      required: true,
      default: () => []
    },
    activeAddress: {
      type: Object as PropType<AccountAddressT>,
      required: false
    },
    canGoBack: {
      type: Boolean,
      required: true
    },
    canGoNext: {
      type: Boolean,
      required: true
    }
  },

  emits: ['next', 'previous']
})
