<template>
  <div class="relative inline-block group w-full py-2">
    <slot />
    <div
      class=" border border-solid py-1 px-1 rounded invisible group-hover:visible absolute z-10 bg-rGrayLightest -mt-full bottom-full w-60 transform"
      :class="{
        'right-0 -mr-2': rightmost,
        'left-0 -ml-2': leftmost,
        '-translate-x-1/2 left-1/2': !rightmost && !leftmost,
        'text-rGrayMed border-rGrayMed': !isWarning,
        'text-rRed border-rRed': isWarning
      }"
    >
      <span class="text whitespace-normal">{{ $t(textKey) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const Tooltip = defineComponent({
  name: 'Tooltip',
  props: {
    textKey: {
      type: String,
      required: true
    },
    rightmost: {
      type: Boolean,
      required: false,
      default: false
    },
    leftmost: {
      type: Boolean,
      required: false,
      default: false
    },
    isWarning: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

export default Tooltip
</script>
