import { createRouter, createWebHashHistory, RouteRecordRaw, RouterScrollBehavior } from 'vue-router'
import Home from '@/views/Home.vue'
import Account from '@/views/Account.vue'
import Transaction from '@/views/Transaction.vue'
import Validators from '@/views/Validators.vue'
import NotFound from '@/views/404.vue'
import Validator from '@/views/Validator.vue'
import ResourceIdentifier from '@/views/ResourceIdentifier.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/accounts/:id',
    name: 'Account',
    component: Account,
    props: route => ({
      accountId: route.params.id
    })
  },
  {
    path: '/transactions/:id',
    name: 'Transaction',
    component: Transaction,
    props: route => ({
      transactionId: route.params.id
    })
  },
  {
    path: '/tokens/:id',
    name: 'Tokens',
    component: ResourceIdentifier,
    props: route => ({
      rri: route.params.id
    })
  },
  {
    path: '/validators/:id',
    name: 'Validator',
    component: Validator,
    props: route => ({
      validatorId: route.params.id
    })
  },
  {
    path: '/validators',
    name: 'Validators',
    component: Validators
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  }
]

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) { return savedPosition }
  return { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior
})

export default router
