
import { defineComponent } from 'vue'

const Tooltip = defineComponent({
  name: 'Tooltip',
  props: {
    textKey: {
      type: String,
      required: true
    },
    rightmost: {
      type: Boolean,
      required: false,
      default: false
    },
    leftmost: {
      type: Boolean,
      required: false,
      default: false
    },
    isWarning: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

export default Tooltip
