<template>
  <template v-if="accountError">
    {{ $t('error.default') }}
  </template>
  <template v-else-if="loading">
    <div class="flex justify-center py-10">
      <loading-icon class="text-rGrayDark" />
    </div>
  </template>
  <template v-else>
    <div class="max-w-4xl mx-auto px-5 py-6">
      <h1 class="my-5 flex justify-between">
        <div>
          <span class="text-rGrayDark pr-2">{{ $t('wallet.headerAddresLabel') }}:</span>
          <span class="text-rGrayDarkest font-medium truncate font-mono hidden md:inline">{{ address.toString() }}</span>
        </div>
        <div class="flex text-rGrayDark text-xs items-center">
          <click-to-copy :text="address.toString()">
            <span>{{ $t('wallet.copyAddress') }}</span>
          </click-to-copy>
        </div>
      </h1>
      <div class="text-rGrayDarkest font-medium truncate font-mono md:hidden overflow-hidden truncate pb-10 -mt-4">{{ address.toString() }}</div>
      <!-- desktop component -->
      <div class="hidden md:flex">
        <div class="border border-rGray rounded-md flex flex-row mb-7 overflow-x-auto">
          <img src="@/assets/token.svg" alt="token symbol" class="p-3 border-r border-rGray" />
          <div class="flex flex-col my-3 px-5 border-r border-rGray flex-1">
            <span class="text-sm text-rGrayDark font-light">{{ $t('wallet.totalTokens') }}</span>
            <div class="flex flex-row items-end">
              <big-amount :amount="availablePlusStakedAndUnstakedXRD" class="text-4xl font-thin mr-4 text-rGreen" />
              <token-symbol
                v-if="nativeToken"
                :hasGreyBackground="true"
                :symbol="nativeToken.symbol"
                :rri="nativeToken.rri.toString()"
              >
              </token-symbol>
            </div>
          </div>
          <div class="flex flex-col my-3 px-5 border-r border-rGray flex-1">
            <span class="text-sm text-rGrayDark font-light">{{ $t('wallet.availableTokens') }}</span>
            <div class="flex flex-row items-end">
              <big-amount :amount="totalXRD" class="text-4xl font-thin mr-4 text-rBlack" />
              <token-symbol
                v-if="nativeToken"
                :hasGreyBackground="true"
                :symbol="nativeToken.symbol"
                :rri="nativeToken.rri.toString()"
              >
              </token-symbol>
            </div>
          </div>
          <div class="flex flex-col my-3 px-5 flex-1">
            <span class="text-sm text-rGrayDark font-light">{{ $t('wallet.stakedTokens') }}</span>
            <div class="flex flex-row items-end">
              <big-amount :amount="totalStakedAndUnstaked" class="text-4xl font-thin mr-4 text-rBlack" />
              <token-symbol
                v-if="nativeToken"
                :hasGreyBackground="true"
                :symbol="nativeToken.symbol"
                :rri="nativeToken.rri.toString()"
              >
              </token-symbol>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile variant -->
      <div class="md:hidden">
        <div class="rounded-md border grid grid-cols-4 gap-4">
          <img src="@/assets/token.svg" alt="token symbol" class="p-3" />
          <div class="col-span-3">
            <div class="text-lg b-4 text-rGrayDark font-light pt-3">{{ $t('wallet.totalTokens') }}</div>
            <big-amount :amount="availablePlusStakedAndUnstakedXRD" class="text-lg font-thin mr-2 text-rGreen" />
            <token-symbol
              v-if="nativeToken"
              :hasGreyBackground="true"
              :symbol="nativeToken.symbol"
              :rri="nativeToken.rri.toString()"
            >
            </token-symbol>
            <div class="border-b pt-4 mr-4"></div>
            <div class="text-lg b-4 text-rGrayDark font-light pt-6">{{ $t('wallet.availableTokens') }}</div>
            <big-amount :amount="totalXRD" class="text-lg font-thin mr-2 text-rBlack" />
            <token-symbol
              v-if="nativeToken"
              :hasGreyBackground="true"
              :symbol="nativeToken.symbol"
              :rri="nativeToken.rri.toString()"
            >
            </token-symbol>
            <div class="border-b pt-4 mr-4"></div>
            <div class="text-lg b-4 text-rGrayDark font-light pt-6">{{ $t('wallet.stakedTokens') }}</div>
            <big-amount :amount="totalStakedAndUnstaked" class="text-lg font-thin mr-2  text-rBlack" />
            <token-symbol
              v-if="nativeToken"
              :hasGreyBackground="true"
              :symbol="nativeToken.symbol"
              :rri="nativeToken.rri.toString()"
            >
            </token-symbol>
            <div class="pb-4"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-4xl mx-auto mb-9 px-5">
      <div class="flex flex-row -mx-5">
        <div class="border border-rGray rounded-md p-5 mx-5 flex-1 overflow-x-auto" v-if="tokenBalanceIsValid">
          <h3 class="font-medium text-rBlack mb-6">{{ $t('wallet.additionalBalances') }}</h3>
          <template v-if="tokenBalanceIsValid">
            <div v-for="(bal, i) in otherTokenBalances" :key="i" class="mb-5 flex flex-row items-center">
              <big-amount :amount="bal.value" class="text-4xl font-thin text- mr-4 text-rBlack" />
              <token-symbol
                :hasGreyBackground="true"
                :symbol="bal.token_identifier.rri.name"
                :rri="bal.token_identifier.rri.toString()"
              >
              </token-symbol>
            </div>
          </template>
        </div>
      </div>
    </div>
    <template v-if="transactionHistoryIsValid && !transactionError">
      <div class="max-w-4xl mx-auto px-5 mb-10 relative">
        <div class="flex justify-between items-center text-sm md:text-base">
          <h1 class="mb-8">{{ $t('wallet.recentTransactions')}}</h1>
          <button class="flex items-center mb-8 gap-1 cursor-pointer" @click="toggleExportDropdown">
            <svg width="14" height="17" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 0V3.5H10.5L7 0ZM6.125 3.5V0H1.3125C0.587617 0 0 0.587617 0 1.3125V12.6875C0 13.4121 0.587617 14 1.3125 14H9.1875C9.91238 14 10.5 13.4124 10.5 12.6875V4.375H7.02461C6.51602 4.375 6.125 3.98398 6.125 3.5ZM3.5 7.65625C3.5 7.77656 3.40156 7.875 3.28125 7.875H3.0625C2.81914 7.875 2.625 8.06914 2.625 8.3125V9.1875C2.625 9.43086 2.81914 9.625 3.0625 9.625H3.28125C3.40156 9.625 3.5 9.72344 3.5 9.84375V10.2812C3.5 10.4016 3.40156 10.5 3.28125 10.5H3.0625C2.33789 10.5 1.75 9.91211 1.75 9.1875V8.3125C1.75 7.58789 2.33789 7 3.0625 7H3.28125C3.40156 7 3.5 7.09844 3.5 7.21875V7.65625ZM4.71133 10.5H4.375C4.25537 10.5 4.15625 10.4009 4.15625 10.2812V9.84375C4.15625 9.72344 4.25469 9.625 4.375 9.625H4.70996C4.87402 9.625 4.99379 9.5293 4.99379 9.44385C4.99379 9.40967 4.97328 9.37207 4.93568 9.33789L4.3374 8.8252C4.10977 8.62695 3.97578 8.34805 3.97578 8.05547C3.97578 7.47578 4.49531 7 5.13516 7H5.46875C5.58838 7 5.6875 7.09912 5.6875 7.21875V7.65625C5.6875 7.77656 5.58906 7.875 5.46875 7.875H5.13516C4.97109 7.875 4.85133 7.9707 4.85133 8.05615C4.85133 8.09033 4.87184 8.12793 4.90943 8.16211L5.50771 8.6748C5.73672 8.87305 5.87002 9.15332 5.87002 9.44371C5.86797 10.0242 5.34844 10.5 4.71133 10.5ZM7.875 7.7875V7.21875C7.875 7.09844 7.97344 7 8.09375 7H8.53125C8.65156 7 8.75 7.09844 8.75 7.21875V7.78613C8.75 8.75684 8.39781 9.67285 7.75879 10.36C7.67812 10.448 7.56055 10.5 7.4375 10.5C7.31445 10.5 7.19824 10.4487 7.11621 10.3599C6.47773 9.67422 6.125 8.7582 6.125 7.7875V7.21875C6.125 7.09844 6.22344 7 6.34375 7H6.78125C6.90156 7 7 7.09844 7 7.21875V7.78613C7 8.3434 7.15723 8.88672 7.4375 9.34145C7.71914 8.88672 7.875 8.34258 7.875 7.7875Z" fill="#7A99AC"/>
            </svg>
            <span class="text-rGrayDark underline hidden md:block"> {{ $t('wallet.exportCSVLabel') }}</span>
          </button>
        </div>
        <!-- loader -->
        <div v-if="showLoader" class="w-11/12 md:w-96 absolute right-4 md:right-5 bg-white rounded-md border z-50 h-72 md:h-64 opacity-95">
          <div v-if="showLoader && !showExportSuccess" class="flex flex-col justify-center items-center rounded-md h-full gap-4">
            <loading-icon-large class="text-rBlue" />
            <span>{{ $t('general.exporting') }} </span>
          </div>
          <div v-if="showExportSuccess" class="flex flex-col justify-center items-center h-full gap-4 rounded-md bg-white">
            <svg width="51" height="58" viewBox="0 0 51 58" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4503_69758)">
              <path d="M24.1951 10V22H36.2927L24.1951 10ZM21.1707 22V10H4.53659C2.03107 10 0 12.0147 0 14.5V53.5C0 55.9844 2.03107 58 4.53659 58H31.7561C34.2616 58 36.2927 55.9853 36.2927 53.5V25H24.2802C22.5223 25 21.1707 23.6594 21.1707 22ZM12.0976 36.25C12.0976 36.6625 11.7573 37 11.3415 37H10.5854C9.74421 37 9.07317 37.6656 9.07317 38.5V41.5C9.07317 42.3344 9.74421 43 10.5854 43H11.3415C11.7573 43 12.0976 43.3375 12.0976 43.75V45.25C12.0976 45.6625 11.7573 46 11.3415 46H10.5854C8.08079 46 6.04878 43.9844 6.04878 41.5V38.5C6.04878 36.0156 8.08079 34 10.5854 34H11.3415C11.7573 34 12.0976 34.3375 12.0976 34.75V36.25ZM16.2845 46H15.122C14.7085 46 14.3659 45.6602 14.3659 45.25V43.75C14.3659 43.3375 14.7061 43 15.122 43H16.2797C16.8468 43 17.2608 42.6719 17.2608 42.3789C17.2608 42.2617 17.1899 42.1328 17.0599 42.0156L14.992 40.2578C14.2052 39.5781 13.7421 38.6219 13.7421 37.6188C13.7421 35.6313 15.5378 34 17.7494 34H18.9024C19.3159 34 19.6585 34.3398 19.6585 34.75V36.25C19.6585 36.6625 19.3183 37 18.9024 37H17.7494C17.1823 37 16.7684 37.3281 16.7684 37.6211C16.7684 37.7383 16.8392 37.8672 16.9692 37.9844L19.0371 39.7422C19.8287 40.4219 20.2894 41.3828 20.2894 42.3784C20.2823 44.3687 18.4866 46 16.2845 46ZM27.2195 36.7V34.75C27.2195 34.3375 27.5598 34 27.9756 34H29.4878C29.9037 34 30.2439 34.3375 30.2439 34.75V36.6953C30.2439 40.0234 29.0266 43.1641 26.8178 45.52C26.539 45.8219 26.1326 46 25.7073 46C25.282 46 24.8803 45.8242 24.5968 45.5195C22.3899 43.1688 21.1707 40.0281 21.1707 36.7V34.75C21.1707 34.3375 21.511 34 21.9268 34H23.439C23.8549 34 24.1951 34.3375 24.1951 34.75V36.6953C24.1951 38.6059 24.7386 40.4688 25.7073 42.0278C26.6808 40.4688 27.2195 38.6031 27.2195 36.7Z" fill="#00C389"/>
              </g>
              <path d="M34 9.06897L38.3333 14L48 3" stroke="#00C389" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
              <defs>
              <clipPath id="clip0_4503_69758">
              <rect width="36.2927" height="48" fill="white" transform="translate(0 10)"/>
              </clipPath>
              </defs>
            </svg>
            <span>{{ $t('general.exportedSuccess') }} </span>
          </div>
        </div>
        <div v-if="showExportDropdown" class="w-11/12 md:w-96 absolute right-4 md:right-5 bg-white rounded-md shadow-lg border z-40 h-72 md:h-64">
          <h2 class="px-4 py-2 text-sm text-rGrayDark border-b md:hidden">{{ $t('wallet.exportCSVLabel') }}</h2>
          <div class="flex items-center justify-between p-4 border-b">
            <span class="text-sm md:text-base">{{ $t('wallet.lastWeekLabel') }}</span>
            <button @click="handleTypeOfExport('week')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5L7.5 2.5" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.3331 5.83325L17.4998 9.99992L13.3331 14.1666" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.5 10L7.5 10" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </button>
          </div>
          <div class="flex items-center justify-between p-4 border-b">
            <span class="text-sm md:text-base">{{ $t('wallet.lastMonthLabel') }}</span>
            <button @click="handleTypeOfExport('month')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5L7.5 2.5" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.3331 5.83325L17.4998 9.99992L13.3331 14.1666" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.5 10L7.5 10" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </button>
          </div>
          <div class="flex items-center justify-between p-4 border-b">
            <span class="text-sm md:text-base">{{ $t('wallet.lastYearLabel') }}</span>
            <button @click="handleTypeOfExport('year')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5L7.5 2.5" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.3331 5.83325L17.4998 9.99992L13.3331 14.1666" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.5 10L7.5 10" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </button>
          </div>
          <div class="flex flex-col p-4">
            <div class="flex items-center justify-center">
              <span class="text-sm md:text-base">{{ $t('wallet.customDateLabel') }}</span>
              <input type="date" name="from" id="from" v-model="fromDate" @change="toggleCustomExportButtonEnabled" class="border-none text-xs md:text-sm text-rGrayDark cursor-pointer">
              <span>to</span>
              <input type="date" name="to" id="to" v-model="toDate" @change="toggleCustomExportButtonEnabled" class="border-none focus:text-rGrayDark text-xs md:text-sm text-rGrayDark cursor-pointer">
              <button :disabled="showDisabledSVG" @click="handleTypeOfExport('custom')">
                <svg v-if="showDisabledSVG" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5L7.5 2.5" stroke="#DDE5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.3333 5.83331L17.4999 9.99998L13.3333 14.1666" stroke="#DDE5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5 10L7.5 10" stroke="#DDE5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M7.5 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5L7.5 2.5" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.3331 5.83325L17.4998 9.99992L13.3331 14.1666" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5 10L7.5 10" stroke="#052CC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
            <div v-if="showDateError === 'date' || showDateError === 'empty'" class="flex items-center border-md justify-center text-xs text-rRed pb-4">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.4026 2.25166L2.46176 10.5C2.3599 10.6764 2.30599 10.8764 2.30542 11.0801C2.30485 11.2838 2.35763 11.4842 2.45851 11.6611C2.55939 11.8381 2.70485 11.9856 2.88043 12.0889C3.056 12.1922 3.25556 12.2478 3.45926 12.25H13.3409C13.5446 12.2478 13.7442 12.1922 13.9198 12.0889C14.0953 11.9856 14.2408 11.8381 14.3417 11.6611C14.4426 11.4842 14.4953 11.2838 14.4948 11.0801C14.4942 10.8764 14.4403 10.6764 14.3384 10.5L9.3976 2.25166C9.2936 2.08023 9.14718 1.93848 8.97246 1.84011C8.79774 1.74174 8.60061 1.69006 8.4001 1.69006C8.19958 1.69006 8.00246 1.74174 7.82773 1.84011C7.65301 1.93848 7.50659 2.08023 7.4026 2.25166Z" fill="#EF4136"/>
                <path d="M8.3999 9.91669H8.4074" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.3999 5.25V7.58333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span v-if="showDateError === 'date'">{{ $t('wallet.enterValidDateErrorMessage') }}</span>
              <span v-if="showDateError === 'empty'">{{ $t('wallet.noTransactionsFoundMessage') }}</span>
            </div>
          </div>
        </div>
        <paginated-transactions
          :transactions="transactionHistory.transactions"
          :activeAddress="address"
          :canGoBack="cursorStack.length > 0"
          :canGoNext="canGoNext"
          @next="handleNextPage"
          @previous="handlePreviousPage"
        />
      </div>
    </template>
    <template v-else>
      <div class="max-w-4xl mx-auto px-5 mb-10">
        <h1 class="mb-8">{{ $t('wallet.transactionsError')}}</h1>
      </div>
    </template>
  </template>
</template>

<script lang="ts">
import { ComputedRef, defineComponent, Ref, watch, ref, computed } from 'vue'
import { Token, Amount, AmountT, AccountAddress, AccountAddressT, SimpleExecutedTransaction, TransactionHistoryRequestInput, ExecutedTransferTokensAction, ExecutedUnstakeTokensAction, ExecutedStakeTokensAction, ExecutedOtherAction, ExecutedAction, ExecutedTransaction } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import { useRouter } from 'vue-router'
import PaginatedTransactions from '@/components/PaginatedTransactions.vue'
import BigAmount, { asBigNumber } from '@/components/BigAmount.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import TokenSymbol from '@/components/TokenSymbol.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import LoadingIconLarge from '@/components/LoadingIconLarge.vue'
import { add } from '@/helpers/arithmetic'
import useRadix from '@/composables/useRadix'
import { Decoded } from '@radixdlt/application/dist/api/open-api/_types'
import Observed from '@/helpers/observed'
import PapaParse from 'papaparse'
import { subWeeks, subMonths, subYears, parse, format, isEqual } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import { DatetimeFormat } from 'vue-i18n'

const PAGE_SIZE = 30

const zero = Amount.fromUnsafe(0)._unsafeUnwrap()

export default defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    LoadingIcon,
    LoadingIconLarge,
    PaginatedTransactions,
    TokenSymbol
  },

  props: {
    accountId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const { radix, nativeToken } = useRadix()
    const tokenBalances: Ref<Observed<ReturnType<typeof radix.ledger.tokenBalancesForAddress>> | null> = ref(null)
    const activeStakes: Ref<Observed<ReturnType<typeof radix.ledger.stakesForAddress>> | null> = ref(null)
    const activeUnstakes: Ref<Observed<ReturnType<typeof radix.ledger.unstakesForAddress>> | null> = ref(null)
    const transactionHistory: Ref<Observed<ReturnType<typeof radix.ledger.transactionHistory>>> = ref({ cursor: '', transactions: [] })

    const tokenBalanceIsValid = ref(false) as Ref<boolean>
    const transactionHistoryIsValid = ref(false) as Ref<boolean>
    const address: Ref<AccountAddressT | null> = ref(null)
    const router = useRouter()
    const accountError: Ref<Error | null> = ref(null)
    const transactionError: Ref<Error | null> = ref(null)
    const canGoNext: Ref<boolean> = ref(false)
    const cursorStack: Ref<string[]> = ref([])

    const showExportDropdown: Ref<boolean> = ref(false)
    const showCustomExportButtonEnabled: Ref<boolean> = ref(false)
    const toDate: Ref<string> = ref('')
    const fromDate: Ref<string> = ref('')
    const showDisabledSVG = ref(true)
    const showDateError = ref('')
    const showLoader: Ref<boolean> = ref(false)
    const showExportSuccess = ref(false)

    const fetchDataForAccount = async (accountId: string) => {
      const initialAddress = AccountAddress.fromUnsafe(accountId)
      if (initialAddress.isErr()) {
        throw Error('Invalid Address')
      }
      address.value = initialAddress.value
      activeStakes.value = await firstValueFrom(radix.ledger.stakesForAddress(initialAddress.value))
      activeUnstakes.value = await firstValueFrom(radix.ledger.unstakesForAddress(initialAddress.value))
      tokenBalances.value = await firstValueFrom(radix.ledger.tokenBalancesForAddress(initialAddress.value))
      tokenBalanceIsValid.value = true
    }

    const fetchTransactions = async (cursor?: string) => {
      if (!address.value) return
      const params = { size: PAGE_SIZE, address: address.value, cursor }
      const data = await firstValueFrom(radix.ledger.transactionHistory(params))

      transactionHistory.value = data

      transactionHistoryIsValid.value = true
      if (data.cursor && data.transactions.length === PAGE_SIZE) {
        canGoNext.value = true
      } else {
        canGoNext.value = false
      }
    }

    const fieldsFor = (action: ExecutedAction) => {
      const transferAction = action as ExecutedTransferTokensAction
      const stakeAction = action as ExecutedStakeTokensAction
      const unstakeAction = action as ExecutedUnstakeTokensAction
      switch (action.type) {
        case 'TokenTransfer':
          return {
            amount: asBigNumber(transferAction.amount),
            toAccount: transferAction.to_account.toString(),
            fromAccount: transferAction.from_account.toString(),
            rri: transferAction.rri.name
          }
        case 'StakeTokens':
          return {
            amount: asBigNumber(stakeAction.amount),
            validator: stakeAction.to_validator.toString(),
            fromAccount: stakeAction.from_account.toString(),
            rri: stakeAction.rri.name
          }
        case 'UnstakeTokens':
          return {
            amount: asBigNumber(stakeAction.amount),
            validator: unstakeAction.from_validator.toString(),
            toAccount: unstakeAction.to_account.toString(),
            rri: stakeAction.rri.name
          }
        case 'Other':
          return {}
      }
    }

    // takes in 2 dates to filter transactions based on sentAt property
    const handleExportTransactions = async (minDate: Date, maxDate: Date) => {
      showLoader.value = true
      const allTransactions = await fetchAllTransactions(minDate)
      const columnsForCSV = {
        columns: ['sentAt', 'txId', 'fee', 'message', 'status', 'type', 'rri', 'toAccount', 'fromAccount', 'validator', 'amount']
      }

      if (allTransactions) {
        // filter by both the date range as well as look inside any airdrop transactions that dont include the accountId
        const filteredTransactions = allTransactions.filter(transaction => {
          const { sentAt } = transaction
          return sentAt >= minDate && sentAt <= maxDate
        }).map(transaction => ({
          ...transaction,
          actions: transaction.actions.filter(action => {
            const { type } = action
            const transferAction = action as ExecutedTransferTokensAction
            const stakeAction = action as ExecutedStakeTokensAction
            const unstakeAction = action as ExecutedUnstakeTokensAction
            switch (type) {
              case 'TokenTransfer':
                return transferAction.to_account === props.accountId || transferAction.from_account === props.accountId
              case 'StakeTokens':
                return stakeAction.from_account === props.accountId || stakeAction.to_validator === props.accountId
              case 'UnstakeTokens':
                return unstakeAction.from_validator === props.accountId || unstakeAction.to_account === props.accountId
            }
          })
        })).filter(transaction => transaction.actions.length > 0)

        if (!filteredTransactions.length) {
          showLoader.value = false
          showDateError.value = 'empty'
          fromDate.value = ''
          toDate.value = ''
          showDisabledSVG.value = true
          return
        }

        showDateError.value = ''
        const dataForCsv = filteredTransactions.flatMap(transaction => {
          const common = {
            sentAt: transaction.sentAt,
            txId: transaction.txID,
            fee: asBigNumber(transaction.fee),
            message: transaction.message,
            status: transaction.status
          }

          return transaction.actions.map((action) => {
            const extra = fieldsFor(action)
            return { ...common, type: action.type, ...extra }
          })
        })
        const csvConfig = PapaParse.unparse(dataForCsv, columnsForCSV)

        // download csv
        const hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvConfig)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'transactions.csv'
        hiddenElement.click()
        showExportSuccess.value = true
        setTimeout(() => {
          showExportSuccess.value = false
          showLoader.value = false
          toDate.value = ''
          fromDate.value = ''
          showDisabledSVG.value = true
          toggleExportDropdown()
        }, 4000)
      }
    }

    // get all user transactions
    const fetchAllTransactions = async (minDate: Date): Promise<SimpleExecutedTransaction[]> => {
      const allCursorStack: string[] = []
      const allFetchedTransactions = []
      let latestPage = []

      if (!address.value) return []
      const params = { size: PAGE_SIZE, address: address.value }
      const data = await firstValueFrom(radix.ledger.transactionHistory(params))
      latestPage = data.transactions
      allFetchedTransactions.push(...data.transactions)
      allCursorStack.push(data.cursor)
      // iterate over data returned from fetch transactions to get all transactions
      while (latestPage.length === PAGE_SIZE) {
        const nextParams: TransactionHistoryRequestInput = { size: PAGE_SIZE, address: address.value, cursor: allCursorStack[allCursorStack.length - 1] }
        const nextData = await firstValueFrom(radix.ledger.transactionHistory(nextParams))
        latestPage = nextData.transactions
        allFetchedTransactions.push(...nextData.transactions)
        allCursorStack.push(nextData.cursor)
        const shouldEnd = nextData.transactions.some((transaction: SimpleExecutedTransaction) => {
          const { sentAt } = transaction
          return sentAt <= minDate
        })
        if (shouldEnd) break
      }

      return allFetchedTransactions
    }

    try {
      fetchDataForAccount(props.accountId).then(() => {
        fetchTransactions()
      })
    } catch (e) {
      router.push({ path: '/404', query: { term: props.accountId } })
    }

    watch(() => props.accountId, (newVal) => {
      tokenBalanceIsValid.value = false
      transactionHistoryIsValid.value = false
      fetchDataForAccount(newVal).then(() => {
        fetchTransactions()
      })
    })

    const handleNextPage = () => {
      transactionHistoryIsValid.value = false
      if (!transactionHistory.value) return
      cursorStack.value.push(transactionHistory.value.cursor)
      fetchTransactions(cursorStack.value[cursorStack.value.length - 1])
    }

    const handlePreviousPage = () => {
      transactionHistoryIsValid.value = false
      cursorStack.value.pop()
      fetchTransactions(cursorStack.value.length > 0 ? cursorStack.value[cursorStack.value.length - 1] : '')
    }

    const loading = computed(() => {
      return (!tokenBalanceIsValid.value || !transactionHistoryIsValid.value) && !!address.value
    })

    const tokenBalanceFor = (token: Token) => {
      if (!tokenBalances.value) return null
      return tokenBalances.value.account_balances.liquid_balances.find((lb) => lb.token_identifier.rri.equals(token.rri)) || null
    }

    const totalXRD: ComputedRef<AmountT> = computed(() => {
      if (!nativeToken.value || !tokenBalances.value) return zero
      const nativeTokenBalance = tokenBalanceFor(nativeToken.value)
      if (!nativeTokenBalance) return zero
      return nativeTokenBalance.value
    })

    const totalStakedAndUnstaked: ComputedRef<AmountT> = computed(() => {
      if (!tokenBalances.value) return zero
      return tokenBalances.value.account_balances.staked_and_unstaking_balance.value || zero
    })

    const availablePlusStakedAndUnstakedXRD: ComputedRef<AmountT> = computed(() => {
      return add(totalXRD.value, totalStakedAndUnstaked.value)
    })

    const otherTokenBalances: ComputedRef<Decoded.TokenAmount[]> = computed(() => {
      if (!tokenBalances.value || !nativeToken.value) return []

      const data = tokenBalances.value.account_balances.liquid_balances.filter((tb) => {
        return !tb.token_identifier.rri.equals(nativeToken.value!.rri)
      })

      return data
    })

    const toggleExportDropdown = (): void => {
      showExportDropdown.value = !showExportDropdown.value
    }

    const toggleCustomExportButtonEnabled = (): boolean => {
      showDateError.value = ''
      if (toDate.value && fromDate.value) {
        showDisabledSVG.value = false
        return true
      } else {
        showDisabledSVG.value = true
        return false
      }
    }

    const handleTypeOfExport = (type: string) => {
      showDateError.value = ''
      const todaysDate = new Date()
      if (type === 'week') {
        const lastWeekDate = subWeeks(todaysDate, 1)
        handleExportTransactions(lastWeekDate, todaysDate)
      } else if (type === 'month') {
        const lastMonthDate = subMonths(todaysDate, 1)
        handleExportTransactions(lastMonthDate, todaysDate)
      } else if (type === 'year') {
        const lastYearDate = subYears(todaysDate, 1)
        handleExportTransactions(lastYearDate, todaysDate)
      } else if (type === 'custom') {
        if (checkForValidDates()) {
          const convertedFromDate = parse(fromDate.value, 'yyyy-MM-dd', new Date())
          const convertedToDate = parse(toDate.value, 'yyyy-MM-dd', new Date())
          // if dates are equal make one at 1200AM and the other at 11:59pm
          if (isEqual(convertedFromDate, convertedToDate)) {
            convertedToDate.setHours(23, 59, 59)
          }
          handleExportTransactions(convertedFromDate, convertedToDate)
        } else {
          showDateError.value = 'date'
        }
      }
    }

    const checkForValidDates = (): boolean => {
      const currentDate = new Date()
      const convertedFromDate = parse(fromDate.value, 'yyyy-MM-dd', new Date())
      const convertedToDate = parse(toDate.value, 'yyyy-MM-dd', new Date())
      // if first date is after second
      // if first date is after current date
      // if second date is after current date
      const a = isAfter(convertedFromDate, convertedToDate)
      const b = isAfter(convertedFromDate, currentDate)
      const c = isAfter(convertedToDate, currentDate)
      if (a || b || c) {
        showDateError.value = 'date'
        return false
      } else {
        showDateError.value = ''
        return true
      }
    }

    return {
      accountError,
      activeStakes,
      activeUnstakes,
      address,
      canGoNext,
      cursorStack,
      loading,
      nativeToken,
      tokenBalanceIsValid,
      tokenBalances,
      transactionError,
      transactionHistory,
      transactionHistoryIsValid,
      totalXRD,
      totalStakedAndUnstaked,
      availablePlusStakedAndUnstakedXRD,
      otherTokenBalances,
      handleNextPage,
      handlePreviousPage,
      handleExportTransactions,
      showExportDropdown,
      showExportSuccess,
      showCustomExportButtonEnabled,
      toggleExportDropdown,
      toggleCustomExportButtonEnabled,
      handleTypeOfExport,
      showDisabledSVG,
      showDateError,
      showLoader,
      toDate,
      fromDate
    }
  }
})
</script>
