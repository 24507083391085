
import { defineComponent, Ref, watch, ref, computed } from 'vue'
import { TransactionIdentifier, Message, ExecutedTransaction } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import ActionExpandedListItemStakeTokens from '@/components/ActionExpandedListItemStakeTokens.vue'
import ActionExpandedListItemUnstakeTokens from '@/components/ActionExpandedListItemUnstakeTokens.vue'
import ActionExpandedListItemTransferTokens from '@/components/ActionExpandedListItemTransferTokens.vue'
import ActionExpandedListItemOther from '@/components/ActionExpandedListItemOther.vue'
import TransactionMessage from '@/components/TransactionMessage.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import useRadix from '@/composables/useRadix'
import TokenSymbol from '@/components/TokenSymbol.vue'

export default defineComponent({
  components: {
    ActionExpandedListItemStakeTokens,
    ActionExpandedListItemUnstakeTokens,
    ActionExpandedListItemTransferTokens,
    ActionExpandedListItemOther,
    BigAmount,
    ClickToCopy,
    LoadingIcon,
    TransactionMessage,
    TokenSymbol
  },

  props: {
    transactionId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const { radix, network, nativeToken } = useRadix()
    const transaction: Ref<ExecutedTransaction | null> = ref(null)
    const error: Ref<Error | null> = ref(null)
    const loading: Ref<boolean> = ref(true)
    const router = useRouter()

    const fetchTransaction = async (id: string) => {
      try {
        // Wrap this in a try/catch because .create will throw an error with an invalid hex string
        const initialIdentifier = TransactionIdentifier.create(id)
        if (initialIdentifier.isErr()) {
          throw Error('Invalid Address')
        }
        transaction.value = await firstValueFrom(radix.ledger.getTransaction(initialIdentifier.value, network.value)) as ExecutedTransaction
        loading.value = false
      } catch (e) {
        error.value = e as Error
      }
    }

    fetchTransaction(props.transactionId)

    watch(() => props.transactionId, (newId: string) => {
      loading.value = true
      fetchTransaction(newId)
    })

    watch(() => error.value, () => {
      router.push({ path: '/404', query: { term: props.transactionId } })
    })

    watch(() => transaction.value, () => {
      if (!transaction.value) { return null }
      if (transaction.value.status === 'PENDING') {
        router.push({ path: '404', query: { term: props.transactionId } })
      }
    })

    const sentAt = computed(() => {
      if (!transaction.value) return ''
      return DateTime.fromJSDate(transaction.value.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
    })

    const messageState = computed(() => {
      if (!transaction.value || !transaction.value.message) { return null }
      return Message.isEncrypted(transaction.value.message) ? 'encrypted' : 'plaintext'
    })

    const transactionStatus = computed(() => {
      if (!transaction.value) { return null }
      return transaction.value.status
    })

    const showMessageLock = computed(() => messageState.value === 'encrypted')

    return { transaction, error, loading, sentAt, messageState, showMessageLock, nativeToken, transactionStatus }
  }
})
