import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { i18n } from '@/text'
import router from './router'

createApp(App)
  .use(router)
  // .use(VueRx)
  .use(i18n)
  .mount('#app')
