<template>
  <template v-if="error">
    {{ $t('error.default') }}
  </template>
  <template v-else-if="loading">
    <div class="flex justify-center py-10">
      <loading-icon class="text-rGrayDark" />
    </div>
  </template>
  <template v-else>
    <div class="max-w-4xl mx-auto px-5 py-6">
      <h1 class="my-5 flex flex-col gap-2">
        <div>
          <span class="text-rGrayDark flex justify-between">{{ $t('transaction.transactionIdLabel') }} <click-to-copy :text="transaction.txID.toString()">
            <span class="text-xs">{{ $t('wallet.copyAddress') }}</span>
          </click-to-copy></span>
        </div>
        <div class="flex text-rGrayDark text-xs items-center">
          <span class="text-rGrayDarkest font-medium truncate ... font-mono">{{transaction.txID.toString()}}</span>
        </div>
      </h1>
      <div :class="{'border-red-500 border rounded-md mb-5': transactionStatus === 'FAILED', 'bg-rOffwhite border border-rGray rounded-md mb-5': transactionStatus !== 'FAILED'}">
        <dl class="divide-y divide-gray-200">
          <div v-if="transactionStatus === 'FAILED'" class="p-5 flex flex-col gap-2">
            <dt class="text-sm font-light text-red-500 text-left">
              Status:
            </dt>
            <dd class="font-light text-sm text-red-500 font-bold mt-0 col-span-3">
              {{ transactionStatus }}
            </dd>
          </div>
          <div class="p-5 flex flex-col gap-2">
            <dt class="text-sm font-light text-rGrayDarker text-left">
              {{ $t('transaction.dateLabel') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3">
              {{ sentAt }}
            </dd>
          </div>
          <div v-if="messageState" class="p-5 flex flex-col gap-2">
            <dt class="text-sm font-light text-rGrayDarker text-left">
              {{ $t('transaction.messageLabel') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3">
              <transaction-message v-if="transaction && transaction.message" :message="transaction.message" />
            </dd>
          </div>

          <div class="p-5 flex flex-col gap-2">
            <dt class="text-sm font-light text-rGrayDarker text-left">
              {{ $t('transaction.feeLabel') }}
            </dt>
            <dd class="font-light text-sm text-rBlack mt-0 col-span-3">
              <big-amount :amount="transaction.fee" class="mr-2" />
              <token-symbol
                :symbol="nativeToken.symbol"
                :rri="nativeToken.rri.toString()"
                :hasGreyBackground="true"
              >
              </token-symbol>
            </dd>
          </div>
        </dl>
      </div>
      <div v-for="(action, i) in transaction.actions" :key="i">
        <action-expanded-list-item-transfer-tokens
          v-if="action.type === 'TokenTransfer'"
          :action="action"
        />
        <action-expanded-list-item-stake-tokens
          v-else-if="action.type === 'StakeTokens'"
          :action="action"
        />
        <action-expanded-list-item-unstake-tokens
          v-else-if="action.type === 'UnstakeTokens'"
          :action="action"
        />
        <action-expanded-list-item-other
          v-else-if="action.type === 'Other' && transactionStatus === 'CONFIRMED'"
          :action="action"
          :transactionStatus="transactionStatus"
        />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, Ref, watch, ref, computed } from 'vue'
import { TransactionIdentifier, Message, ExecutedTransaction } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import ActionExpandedListItemStakeTokens from '@/components/ActionExpandedListItemStakeTokens.vue'
import ActionExpandedListItemUnstakeTokens from '@/components/ActionExpandedListItemUnstakeTokens.vue'
import ActionExpandedListItemTransferTokens from '@/components/ActionExpandedListItemTransferTokens.vue'
import ActionExpandedListItemOther from '@/components/ActionExpandedListItemOther.vue'
import TransactionMessage from '@/components/TransactionMessage.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import useRadix from '@/composables/useRadix'
import TokenSymbol from '@/components/TokenSymbol.vue'

export default defineComponent({
  components: {
    ActionExpandedListItemStakeTokens,
    ActionExpandedListItemUnstakeTokens,
    ActionExpandedListItemTransferTokens,
    ActionExpandedListItemOther,
    BigAmount,
    ClickToCopy,
    LoadingIcon,
    TransactionMessage,
    TokenSymbol
  },

  props: {
    transactionId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const { radix, network, nativeToken } = useRadix()
    const transaction: Ref<ExecutedTransaction | null> = ref(null)
    const error: Ref<Error | null> = ref(null)
    const loading: Ref<boolean> = ref(true)
    const router = useRouter()

    const fetchTransaction = async (id: string) => {
      try {
        // Wrap this in a try/catch because .create will throw an error with an invalid hex string
        const initialIdentifier = TransactionIdentifier.create(id)
        if (initialIdentifier.isErr()) {
          throw Error('Invalid Address')
        }
        transaction.value = await firstValueFrom(radix.ledger.getTransaction(initialIdentifier.value, network.value)) as ExecutedTransaction
        loading.value = false
      } catch (e) {
        error.value = e as Error
      }
    }

    fetchTransaction(props.transactionId)

    watch(() => props.transactionId, (newId: string) => {
      loading.value = true
      fetchTransaction(newId)
    })

    watch(() => error.value, () => {
      router.push({ path: '/404', query: { term: props.transactionId } })
    })

    watch(() => transaction.value, () => {
      if (!transaction.value) { return null }
      if (transaction.value.status === 'PENDING') {
        router.push({ path: '404', query: { term: props.transactionId } })
      }
    })

    const sentAt = computed(() => {
      if (!transaction.value) return ''
      return DateTime.fromJSDate(transaction.value.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
    })

    const messageState = computed(() => {
      if (!transaction.value || !transaction.value.message) { return null }
      return Message.isEncrypted(transaction.value.message) ? 'encrypted' : 'plaintext'
    })

    const transactionStatus = computed(() => {
      if (!transaction.value) { return null }
      return transaction.value.status
    })

    const showMessageLock = computed(() => messageState.value === 'encrypted')

    return { transaction, error, loading, sentAt, messageState, showMessageLock, nativeToken, transactionStatus }
  }
})
</script>
