
import { defineComponent } from 'vue'

const ClickToCopy = defineComponent({
  props: {
    text: {
      type: String,
      required: true
    }
  },

  methods: {
    copyText () {
      navigator.clipboard.writeText(this.text)
    }
  }
})

export default ClickToCopy
