import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    general: {
      loading: 'Loading...',
      exporting: 'Exporting CSV...',
      exportedSuccess: 'CSV Exported'
    },
    validations: {
      default: '%{field} is invalid',
      required: '%{field} is required',
      confirmed: '%{field} doesn\'t match',
      length: '%{field} is the wrong length',
      validAddress: 'Enter a valid address',
      validAmount: 'Enter a valid amount',
      insufficientFunds: '%{field} cannot be greater than account balance',
      amountOfType: 'Requested amount to send is not a mulltiple of token granularity (%{granularity}), will be unable to send',
      invalidPin: 'Your pin was not a match. Try again.',
      incorrectPassword: 'Your password is incorrect. Try again.'
    },
    error: {
      default: 'There was an error fetching your data. Try again.'
    },
    404: {
      title: 'No results for your search.',
      content: 'Please check that you have entered a correct address, validator ID, transaction ID, or token RRI.'
    },
    navigation: {
      validators: 'Validators'
    },
    home: {
      welcomeOne: 'Welcome to the Radix Betanet.',
      welcomeTwo: 'Decentralized finance applications are currently being built on protocols that were not designed to meet the needs and requirements of DeFi services.',
      welcomeThree: 'Radix is using our significant technology innovations to be the first layer 1 protocol specifically built to serve the rapidly growing DeFi industry.',
      passwordTitle: 'Enter your password to access your wallet',
      passwordPlaceholder: 'enter password',
      passwordButton: 'Open Wallet',
      createTitle: 'I don\'t have a wallet yet!',
      createContent: 'You’re new to Radix, create a new wallet to get started holding, sending, and staking XRD.',
      createButton: 'Create a new wallet',
      restoreTitle: 'I already have a wallet',
      restoreContent: 'You have an existing wallet and know your recovery password or have a hardware wallet start here.',
      restoreButton: 'Restore a previous wallet',
      recentTransactions: 'Recent Transactions',
      moreTransactionsLabel: 'more actions for this transaction'
    },
    createWallet: {
      recoveryTitle: 'Recovery Phrase',
      recoveryHelpOne: 'The following 12 words are the seed of your new account. As long as you have them, you will always be able to recover your account, but remember not to store them digitally.',
      recoveryButtonOne: 'I\'ve written down my recovery phrase',
      recoveryHelpTwo: 'Please enter your 12-word mnemonic. The words must be in the correct order.',
      recoveryButtonTwoDisabled: 'Fill these out first',
      recoveryButtonTwoEnabled: 'I\'ve done it!',
      passwordTitle: 'Password',
      passwordHelp: 'Please enter a secure password here.  This password secures your mnemonicly generated key, and will be required every time you open this application',
      passwordPlaceholder: 'enter password',
      passwordConfirmationPlaceholder: 'confirm password',
      passwordButton: 'Confirm password',
      pinTitle: 'PIN',
      pinHelpOne: 'Please enter a secure PIN. This will be used to verify all transactions made in the Wallet.',
      pinHelpTwo: 'Please confirm your PIN.',
      pinButton: 'Confirm PIN'
    },
    restoreWallet: {
      recoveryTitle: 'Recovery Phrase',
      recoveryHelp: 'Enter your 12 word mnemonic to restore your wallet.',
      recoveryButtonDisabled: 'Fill these out first',
      recoveryButtonEnabled: 'I\'ve done it!',
      passwordTitle: 'Password',
      passwordHelp: 'Please enter a secure password here. This password secures your mnemonicly generated key, and will be required every time you open this application',
      pinTitle: 'PIN',
      pinHelpOne: 'Please enter a secure PIN. This will be used to verify all transactions made in the Wallet.',
      pinHelpTwo: 'Please confirm your PIN.'
    },
    wallet: {
      addressLabel: 'addr:',
      back: 'back',
      addAccount: 'Add Account',
      balancesHeading: 'XRD Balances',
      additionalBalancesHeading: 'Additional Balances',
      copyAddress: 'Copy Address',
      headerAddresLabel: 'Address',
      totalTokens: 'Total',
      availableTokens: 'Available',
      stakedTokens: 'Staked or Unstaking',
      navBalances: 'Balances',
      navHistory: 'History',
      navTransaction: 'Send Tokens',
      navStake: 'Stake & Unstake',
      navSettings: 'Wallet Settings',
      recentTransactions: 'Recent Transactions',
      transactionsError: 'We are unable to load recent transactions.',
      additionalBalances: 'Additional Balances',
      exportCSVLabel: 'Export CSV of Recent Transactions',
      lastWeekLabel: 'Last Week',
      lastMonthLabel: 'Last Month',
      lastYearLabel: 'Last Year',
      customDateLabel: 'Custom:',
      enterValidDateErrorMessage: 'please enter a valid date range',
      noTransactionsFoundMessage: 'there were no transactions within this range'
    },
    history: {
      historyHeading: 'History',
      unstakeAction: 'Request Unstake',
      validatorLabel: 'Validator',
      receivedAction: 'Received',
      sentAction: 'Sent',
      toLabel: 'To',
      fromLabel: 'From',
      stakeAction: 'Stake',
      otherAction: 'Other',
      previous: 'Previous',
      next: 'Next'
    },
    transaction: {
      transactionIdLabel: 'Transaction ID:',
      dateLabel: 'Date:',
      feeLabel: 'Fee:',
      typeLabel: 'Type:',
      toLabel: 'To:',
      fromLabel: 'From:',
      amountLabel: 'Amount:',
      validatorLabel: 'Validator:',
      messageLabel: 'Message:'
    },
    staking: {
      currentStakesHeading: 'Current Stakes',
      stakeTab: 'Stake Tokens',
      unstakeTab: 'Unstake Tokens',
      unstakingLabel: 'Unstaking',
      addButton: 'Add',
      reduceButton: 'Reduce',
      fromLabel: 'Staking Account',
      validatorLabel: 'Validator',
      amountLabel: 'Amount',
      feeLabel: 'Fee',
      stakeButton: 'Stake',
      unstakeButton: 'Request Unstake',
      stakeDisclaimer: 'WRITE STAKE COPY Network health explainer with link to FAQ and Validator link, short paragraph. dit diam. Maecenas malesuada tortor turpis, vitae mollis magna elementunas malesuada tortor turpis, vitae mollis magna elementum in. Proin rhoncus vulputate erat, eget aliquam lacus dignissim vel.',
      unstakeDisclaimer: 'WRITE UNSTAKE COPY Network health explainer with link to FAQ and Validator link, short paragraph. dit diam. Maecenas malesuada tortor turpis, vitae mollis magna elementunas malesuada tortor turpis, vitae mollis magna elementum in. Proin rhoncus vulputate erat, eget aliquam lacus dignissim vel.',
      availableBalancePlaceholder: 'Available balance ...',
      validatorPlaceholder: 'enter validator address'
    },
    account: {
      editNameHeading: 'Let’s set the name of your account',
      nameInputPlaceholder: 'Name me!',
      updateNameButton: 'Save'
    },
    settings: {
      currentPinLabel: 'enter current PIN',
      pinLabel: 'enter new PIN',
      confirmationPinLabel: 'confirm new PIN',
      mnemonicDisclaimer: 'WRITE  COPY Be careful, blah blah blah, vitae mollis magna elementunas malesuada tortor turpis, vitae mollis magna elementum in. Proin rhoncus vulputate erat, eget aliquam lacus dignissim vel.da tortor turpis, vitae mollis magna elementum in. Proin rhoncus vulputate erat, eim vel.',
      mnemonicModalHeading: 'Enter your pin to access your mnemonic',
      accessMnemonicButton: 'Access Mnemonic',
      resetPasswordDisclaimer: 'WRITE  COPY Maecenas malesuada tortor turpis, vitae mollis magna elementunas malesuada tortor turpis, vitae mollisdignissim vel.'
    },
    validators: {
      validatorLabel: 'Validator',
      stakeLabel: 'Stake',
      ownerStakeLabel: 'Owner Stake',
      recentUptimeLabel: 'Recent Uptime',
      addressLabel: 'Address',
      websiteLabel: 'Website',
      adsLabel: 'ADS',
      feePercentLabel: 'Fee %',
      validatorTooltip: 'Name specified by the validator node-runner',
      stakeTooltip: 'The total XRD token stake (and % of top-100 stake) delegated to the validator, effective next epoch',
      adsTooltip: '"Accepts delegated stake" - a checkmark indicates that anyone may delegate stake to the validator',
      ownerStakeTooltip: 'The amount of XRD tokens staked from a single specific account controlled by the validator node-runner',
      recentUptimeTooltip: 'The % of consensus "proposals" successfully made by the validator over the past two weeks',
      addressTooltip: 'The validator ID address used if you wish to delegate stake to this validator',
      websiteTooltip: 'An unverified URL provided by the validator node-runner intended to provide information about their node',
      feePercentTooltip: 'A percentage taken by the validator from the total XRD emissions otherwise due to the validator node’s stakers',
      validatorWarning: 'This URL was provided by the node-runner and is unverified. Click at your own risk.',
      notPreferred: 'Not Preferred',
      doNotStake: 'Do Not Stake',
      betanetWarning: 'This is a betanet explorer only. Staking currently is only for test purposes and the amounts have no meaning.',
      activeValidatorsEpoch: 'Above are the active validators as of the next epoch'
    },
    validator: {
      addressLabel: 'Address',
      validatorAddressLabel: 'Validator Address',
      validatorHeaderIDLabel: 'Validator ID',
      validatorNameLabel: 'Validator Name',
      delegatedStakeLabel: 'Delegated Stake',
      stakePercentLabel: 'Stake Percent',
      ownerDelegationLabel: 'Owner Delegation',
      validatorUrlLabel: 'Validator Info URL',
      ownerAddressLabel: 'Owner Address',
      noNameProvided: 'No name provided',
      acceptsStakeLabel: 'Accepts Delegated Stake',
      greenTooltip: 'Accepting Stake',
      yellowTooltip: 'Not Accepting Stake',
      redTooltip: 'Unregistered'
    },
    resourceIdentifier: {
      symbol: 'Symbol',
      supply: 'Supply',
      url: 'URL',
      creatorAddress: 'Creator Address',
      description: 'Description',
      mutable: 'Mutable Supply',
      name: 'Name'
    },
    listItem: {
      encryptedMessage: 'Encrypted Message'
    }
  }
}

export const i18n = createI18n({
  locale: 'en',
  messages
})
