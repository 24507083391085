
import { defineComponent, PropType } from 'vue'
import {
  ExecutedStakeTokensAction,
  ValidatorAddressT
} from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import { formatValidatorAddressForDisplay } from '@/helpers/formatter'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionListItemStakeTokens = defineComponent({
  components: {
    ClickToCopy,
    BigAmount,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedStakeTokensAction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  methods: {
    shortenedAddress (address: ValidatorAddressT): string {
      return formatValidatorAddressForDisplay(address)
    }
  },

  computed: {
    validatorURL (): string {
      return `/validators/${this.action.to_validator.toString()}`
    }
  }
})

export default ActionListItemStakeTokens
