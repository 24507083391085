
import NavBar from '@/components/NavBar.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import useRadix from '@/composables/useRadix'
import { onMounted, defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
    LoadingIcon,
    NavBar
  },

  setup () {
    const { connected, establishConnection } = useRadix()

    onMounted(establishConnection)

    return { connected }
  }
})
