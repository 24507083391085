<template>
  <div v-if="isLoadingValidator || isLoadingValidators" class="flex justify-center my-12">
    <loading-icon class="text-rGrayDark" />
  </div>

  <div v-else class="flex flex-col max-w-4xl mx-auto py-6 px-5">
    <div class="py-5">
      <div v-if="validator.address">
        <h1 class="flex justify-between block w-full hidden md:flex">
          <div class="truncate">
            <span class="text-rGrayDark pr-5">{{ $t('validator.validatorHeaderIDLabel') }}:</span>
            <span class="text-rGrayDarkest font-medium font-mono truncate">{{ validator.address.toString() }}</span>
          </div>
          <div class="flex text-rGrayDark text-xs items-center">
            <click-to-copy :text="validator.address.toString()">
              <span>{{ $t('wallet.copyAddress') }}</span>
            </click-to-copy>
          </div>
        </h1>
        <div class="grid grid-cols-2 text-rGrayDark md:hidden">
          <div>
            {{ $t('validator.addressLabel') }}:
          </div>
          <div class="ml-auto">
            <click-to-copy :text="validator.address.toString()">
              <span>{{ $t('wallet.copyAddress') }}</span>
            </click-to-copy>
          </div>
          <div class="col-span-2 break-words text-rDarkBlue">
            {{ validator.address.toString() }}
          </div>
        </div>
      </div>
      <div v-else class="text-left text-xl lg:w-1/2">
        {{ $t('validator.noNameProvided') }}
      </div>
    </div>
    <div class="bg-rOffwhite border border-rGray rounded-md p-0" v-if="validator">
      <dl class="divide-y divide-gray-200">
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.validatorUrlLabel') }}
          </dt>
          <dd class="font-light text-sm text-rBlack mt-0 col-span-4 md:col-span-3 inline-flex items-center">
              <Tooltip
                v-if="validator.infoURL && validatedValidatorUrl"
                textKey="validators.validatorWarning"
                :isWarning="true"
                :leftmost="true"
              >
                <a :href="validator.infoURL" target="__blank" class="text-rBlue block -my-2 ml-4">
                  {{validator.infoURL.toString()}}
                </a>
              </Tooltip>
            <span v-else> - </span>
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.validatorNameLabel') }}
          </dt>
          <dd class="font-light text-sm text-rBlack mt-0 col-span-4 ml-4 md:col-span-3 inline-flex items-center overflow-x-auto font-mono">
            <span v-if="validator.name">
              {{validator.name}}
            </span>
            <span v-else>
              {{ $t('validator.noNameProvided') }}
            </span>
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.delegatedStakeLabel') }}
          </dt>
          <dd class="relative font-light text-sm text-rBlack mt-0 col-span-4 ml-4 md:col-span-3">
            <big-amount
              :amount="validator.totalDelegatedStake"
              :overrideDisplayNumber="displayAmount(validator.totalDelegatedStake)"
              class="mr-2"
            />
            <token-symbol
              :symbol="nativeToken.symbol"
              :rri="nativeToken.rri.toString()"
              :hasGreyBackground="true"
            >
            </token-symbol>
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.stakePercentLabel') }}
          </dt>
          <dd class="font-light text-sm text-rBlack mt-0 col-span-4 ml-4 md:col-span-3" v-html="stakePercentageContent">
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.ownerDelegationLabel') }}
          </dt>
          <dd class="text-sm text-rGrayDarker mt-0 col-span-4 ml-4 md:col-span-3">
            <big-amount
              :amount="validator.ownerDelegation"
              :overrideDisplayNumber="displayAmount(validator.ownerDelegation)"
              class="mr-2"
            />
            <token-symbol
              :symbol="nativeToken.symbol"
              :rri="nativeToken.rri.toString()"
              :hasGreyBackground="true"
            >
            </token-symbol>
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.ownerAddressLabel') }}
          </dt>
           <click-to-copy :text="validator.ownerAddress.toString()" class="md:hidden text-rGrayDark col-span-2 justify-end ml-1"/>
          <dd class="text-sm text-rGrayDarker mt-0 col-span-4 ml-4 md:col-span-3 flex">
            <h1 class="md:truncate break-all pr-4">
           <router-link class="text-rBlue font-mono" :to="`/accounts/${validator.ownerAddress.toString()}`">{{ validator.ownerAddress.toString() }}</router-link>
            </h1>
           <click-to-copy :text="validator.ownerAddress.toString()" class="hidden md:flex text-rGrayDark flex-row-reverse justify-end ml-1"/>
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validators.feePercentLabel') }}
          </dt>
          <dd class="text-sm text-rGrayDarker mt-0 col-span-4 ml-4 md:col-span-3 flex">
            {{ validator.validatorFee }}%
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validators.recentUptimeLabel') }}
          </dt>
          <dd class="text-sm text-rGrayDarker mt-0 col-span-4 ml-4 md:col-span-3 flex">
             {{ uptimeContent }}
          </dd>
        </div>
        <div class="py-5 grid grid-cols-4 gap-4">
          <dt class="text-sm font-light text-rGrayDarker md:text-right col-span-2 md:col-span-1 ml-4">
            {{ $t('validator.acceptsStakeLabel') }}
          </dt>
          <dd class="font-light text-sm text-rBlack mt-0 col-span-4 ml-4 md:col-span-3 -mt-1">
            <div v-if="validator.isExternalStakeAccepted && validator.registered" class="flex items-center">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 flex-grow-0  ">
                <circle cx="7" cy="7" r="6.5" transform="rotate(90 7 7)" fill="#00C389" stroke="#00C389"/>
                <path d="M6.00061 10.4128L3.5 7.91205L4.24235 7.16963L5.94181 8.86888L9.94665 4.19995L10.7437 4.88357L6.00061 10.4128Z" fill="white"/>
              </svg>
              <p class="ml-4">{{ $t('validator.greenTooltip') }}</p>
            </div>
            <div v-else-if="validator.registered && !validator.isExternalStakeAccepted" class="flex items-center">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 flex-grow-0">
                <circle cx="7" cy="7" r="7" transform="rotate(90 7 7)" fill="#F9D100"/>
                <path d="M3 7H11" stroke="white" stroke-width="1.5"/>
              </svg>
              <p class="ml-4">{{ $t('validator.yellowTooltip') }}</p>
            </div>
            <div v-else class="flex items-center">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 flex-grow-0">
                <circle cx="7" cy="7" r="6.5" transform="rotate(90 7 7)" fill="#EF4136" stroke="#EF4136"/>
                <rect x="4" y="5" width="1" height="7" transform="rotate(-45 4 5)" fill="white"/>
                <rect x="8.94977" y="4.29285" width="1" height="7" transform="rotate(45 8.94977 4.29285)" fill="white"/>
              </svg>
              <p class="ml-4">{{ $t('validator.redTooltip') }}</p>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, watch, computed } from 'vue'
import { Validator, ValidatorAddress, AmountT } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import LoadingIcon from '@/components/LoadingIcon.vue'
import BigNumber from 'bignumber.js'
import BigAmount from '@/components/BigAmount.vue'
import Tooltip from '@/components/Tooltip.vue'
import { useRoute, useRouter } from 'vue-router'
import ClickToCopy from '@/components/ClickToCopy.vue'
import useRadix from '@/composables/useRadix'
import useValidators from '@/composables/useValidators'
import { useI18n } from 'vue-i18n'
import { checkValidatorUrlExploitable } from '@/helpers/urlHelpers'
import TokenSymbol from '@/components/TokenSymbol.vue'

BigNumber.set({
  ROUNDING_MODE: BigNumber.ROUND_FLOOR,
  EXPONENTIAL_AT: [-30, 30]
})

export default defineComponent({
  components: {
    BigAmount,
    ClickToCopy,
    LoadingIcon,
    TokenSymbol,
    Tooltip
  },

  props: {
    validatorId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const { t } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const router = useRouter()
    const validator: Ref<Validator | null> = ref(null)
    const isLoadingValidator: Ref<boolean> = ref(true)

    const { radix, network, nativeToken } = useRadix()
    const {
      bottomValidators,
      validators,
      isLoadingValidators,
      totalStaked,
      fetchValidators
    } = useValidators(radix, network.value)

    fetchValidators()

    const fetchData = async (validatorId: string) => {
      const initialAddress = ValidatorAddress.fromUnsafe(validatorId)
      if (initialAddress.isErr()) {
        throw Error('Invalid Address')
      }

      const validatorRes = await firstValueFrom(radix.ledger.lookupValidator(initialAddress.value)) as Validator
      validator.value = validatorRes
      isLoadingValidator.value = false
    }

    try {
      fetchData(props.validatorId)
    } catch {
      router.push({ path: '/404', query: { term: route.params.validatorId } })
    }

    watch(() => props.validatorId, (newId: string) => {
      isLoadingValidator.value = true
      fetchData(newId)
    })

    const validatorName = computed(() => {
      if (validator.value && validator.value.name) return validator.value.name
      return 'No name provided'
    })

    const validatorAddress = computed(() => {
      if (validator.value) {
        const address = validator.value.address.toString()
        return `${address.slice(0, 11)}...${address.slice(-11)}`
      }
      return ''
    })

    const ownerAddressURL = computed(() => {
      if (validator.value) {
        return `/#/accounts/${validator.value.ownerAddress.toString()}`
      }
      return ''
    })

    const stakePercentage = computed(() => {
      if (!validator.value) return '0'
      const bigVS = new BigNumber(validator.value.totalDelegatedStake.toString())
      const bigTS = new BigNumber(totalStaked.value.toString())
      return bigVS.dividedBy(bigTS).multipliedBy(100).toFixed(2)
    })

    const stakePercentageContent = computed(() => {
      if (!validators.value || validators.value.length <= 0 || !validator.value) return t('general.loading')
      const activeValidatorAddress = validator.value.address
      const maybeVal = validators.value.find(v => v.address.equals(activeValidatorAddress))
      return maybeVal !== undefined
        ? `${stakePercentage.value}%`
        : 'Not in <a href="https://learn.radixdlt.com/article/how-are-validators-selected-for-the-olympia-mainnet" class="text-rBlue" target="__blank">active top 100 validators</a> by stake'
    })

    const uptimeContent = computed(() => {
      if (!validators.value || validators.value.length <= 0 || !validator.value) return '-'
      const activeValidatorAddress = validator.value.address
      const maybeVal = bottomValidators.value.find(v => v.validator.address.equals(activeValidatorAddress))
      return maybeVal !== undefined
        ? '-'
        : `${validator.value.uptimePercentage}%`
    })

    const validatedValidatorUrl = computed(() => {
      if (!validator.value) return false
      return checkValidatorUrlExploitable(validator.value.infoURL.toString())
    })

    const displayAmount = (amount: AmountT): string => {
      const bigNumber = new BigNumber(amount.toString())
      const shiftedAmount = bigNumber.shiftedBy(-18) // Atto
      const bigOne = new BigNumber(1)
      const bigZero = new BigNumber(0)
      if (shiftedAmount.isGreaterThan(bigOne)) {
        return shiftedAmount.toFormat(0)
      } else if (shiftedAmount.isGreaterThan(bigZero)) {
        return '< 1'
      } else {
        return '0'
      }
    }

    return {
      nativeToken,
      // Refs
      validator,
      validators,
      isLoadingValidators,
      isLoadingValidator,
      totalStaked,
      // Computed Properties
      validatorName,
      validatorAddress,
      ownerAddressURL,
      stakePercentage,
      stakePercentageContent,
      validatedValidatorUrl,
      uptimeContent,

      // Methods
      displayAmount
    }
  }
})
</script>
