import { AccountAddressT, TransactionIdentifierT, ValidatorAddressT } from '@radixdlt/application'

export const formatWalletAddressForDisplay = function (address: AccountAddressT): string {
  const s = address.toString()
  return s.substring(0, 3) + '...' + s.substring(s.length - 9)
}

export const formatValidatorAddressForDisplay = function (address: ValidatorAddressT): string {
  const s = address.toString()
  return formatValidatorAddressString(s)
}

export const formatValidatorAddressString = function (address: string): string {
  return address.substring(0, 2) + '...' + address.substring(address.length - 9)
}

export const formatTransactionAddressForDisplay = function (address: TransactionIdentifierT): string {
  const s = address.toString()
  return `${s.slice(0, 5)}...${s.slice(-5)}`
}
