
import { defineComponent, PropType } from 'vue'
import { ExecutedOtherAction } from '@radixdlt/application'

const ActionListItemOther = defineComponent({
  props: {
    action: {
      type: Object as PropType<ExecutedOtherAction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
})

export default ActionListItemOther
