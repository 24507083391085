
import { defineComponent, PropType } from 'vue'
import { ExecutedUnstakeTokensAction } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount from '@/components/BigAmount.vue'
import { formatValidatorAddressString } from '@/helpers/formatter'
import TokenSymbol from '@/components/TokenSymbol.vue'

const ActionListItemUnstakeTokens = defineComponent({
  components: {
    ClickToCopy,
    BigAmount,
    TokenSymbol
  },

  props: {
    action: {
      type: Object as PropType<ExecutedUnstakeTokensAction>,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  computed: {
    validatorURL (): string {
      return `/validators/${this.action.from_validator.toString()}`
    },

    shortenedAddress (): string {
      return formatValidatorAddressString(this.action.from_validator)
    }
  }
})

export default ActionListItemUnstakeTokens
