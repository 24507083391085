<template>
<div v-if="loading">
    <div class="flex justify-center py-10">
      <loading-icon class="text-rGrayDark" />
    </div>
  </div>
  <div v-else>
    <div class="max-w-5xl mx-auto w-full p-5">
      <h1 class="mt-8 font-medium">{{ $t('home.recentTransactions')}}</h1>
    </div>
    <div v-if="recents.transactions" class="flex flex-col max-w-5xl mx-auto px-5">
      <div v-for="(txn, i) in recents.transactions" :key="i" class="flex border border-rGray rounded-md mb-2 h-full flex-col md:flex-row ">
        <div v-if="txn.status === 'CONFIRMED'" class="bg-rGrayLightest text-rGrayDark text-sm pl-3 md:w-44 flex flex-row md:flex-col space-y-2 justify-between md:justify-start items-baseline md:items-center p-3">
          <div class="inline-flex">
            <router-link :to="transactionAddress(txn)" class="text-rBlue text-sm">
              {{ shortenedTransactionID(txn) }}
            </router-link>
            <click-to-copy :text="txn.txID.toString()" />
          </div>
          <div class="text-xs flex items-center">
            <span>{{ sentAt(txn) }}</span>
          </div>
        </div>
        <div class="w-full flex flex-col p-1">
          <!-- actions items -->
          <!-- if token transfer -->
          <div v-for="(action, i) in txn.actions" :key="i" class="w-full">
            <div v-if="i < 3" class="p-1 h-full items-center">
              <!-- row -->
              <div v-if="action.type === 'TokenTransfer'" class="grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 w-full px-3 py-1">
                <div class="grid grid-cols-2 md:flex gap-5 justify-between w-full">
                  <div class="flex items-center gap-2 w-52">
                    <!-- svg -->
                    <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 0.345093L25.3937 5.73879L20 11.1326V0.345093Z" fill="#4F758B"/>
                      <path d="M20.5738 5.73865H13" stroke="#4F758B" stroke-width="1.8667" stroke-miterlimit="10"/>
                      <circle cx="5" cy="5.34509" r="4" stroke="#7A99AC" stroke-width="1.8667" stroke-miterlimit="10"/>
                    </svg>
                    <!-- type -->
                    <span class="text-xs text-rGrayMed">{{ action.type }}</span>
                  </div>
                  <div class="flex items-center gap-1 text-sm justify-start w-full">
                    <span>{{ formatAmount(action.amount) }}</span>
                    <span class="text-rGrayMed w-full">{{ action.rri.name.toUpperCase() }}</span>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-4 justify-between">
                  <div class="flex flex-col md:flex-row items-center gap-1 text-xs w-full">
                    <span class="text-rGrayDark w-full md:w-24 block">From:</span>
                    <span class="flex items-center w-full">
                      <router-link :to="`/accounts/${action.from_account.toString()}`" class="text-rBlue text-sm">
                        {{ shortenedAddress(action.from_account.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.from_account.toString()" class="text-rGrayDark" />
                    </span>
                  </div>
                  <div class="flex flex-col md:flex-row items-center gap-1 text-xs justify-start w-full">
                    <span class="text-rGrayDark w-full md:w-24">To:</span>
                    <span class="flex items-center w-full">
                      <router-link :to="`/accounts/${action.to_account.toString()}`" class="text-rBlue text-sm">
                        {{ shortenedAddress(action.to_account.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.to_account.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                </div>
              </div>
              <!-- row -->
              <!-- UnstakeTokens -->
              <div v-if="action.type === 'UnstakeTokens'" class="grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 w-full px-3 py-1">
                <!-- column 1 -->
                <div class="grid grid-cols-2 md:flex gap-5 justify-between w-full">
                  <div class="flex items-center gap-2 w-52">
                    <!-- svg -->
                    <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.3937 1.34509L0 6.73879L5.3937 12.1326V1.34509Z" fill="#4F758B"/>
                      <path d="M4.8199 6.73865H12.3937" stroke="#4F758B" stroke-width="1.8667" stroke-miterlimit="10"/>
                      <path d="M17.0486 5.28021V4.14496C17.0486 2.59865 18.3022 1.34509 19.8485 1.34509C21.3948 1.34509 22.6484 2.59865 22.6484 4.14496V5.28021" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
                      <path d="M16 11.7448V5.18939H17.1345H22.5874H23.7219V11.4002H17.6056" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                    <!-- type -->
                    <span class="text-xs text-rGrayMed">{{ action.type }}</span>
                  </div>
                  <div class="flex items-center gap-1 text-sm justify-start w-full">
                    <span>{{ formatAmount(action.amount) }}</span>
                    <span class="text-rGrayDark">{{ action.rri.name.toUpperCase() }}</span>
                  </div>
                </div>
                <!-- column 2 -->
                <div class="grid grid-cols-2 gap-4 justify-between">
                  <div class="flex flex-col md:flex-row items-center gap-1 text-xs justify-start w-full">
                    <span class="text-rGrayDark w-full md:w-24">Validator:</span>
                    <span class="flex items-center w-full">
                      <router-link :to="`/validators/${action.from_validator.toString()}`" class="text-rBlue text-sm">
                        {{ shortenedValidator(action.from_validator.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.from_validator.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                  <div class="flex flex-col md:flex-row items-center gap-1 text-xs justify-start w-full">
                    <span class="text-rGrayMed w-full md:w-24">Unstaker:</span>
                    <span class="flex items-center w-full">
                      <router-link :to="`/accounts/${action.to_account.toString()}`" class="text-rBlue text-sm">
                          {{ shortenedAddress(action.to_account.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.to_account.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                </div>
              </div>
              <!-- StakeTokens -->
              <div v-if="action.type === 'StakeTokens'" class="grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 w-full px-3 py-1">
                <!-- column 1 -->
                <div class="flex gap-5 justify-between w-full">
                  <div class="flex items-center gap-2 w-52">
                    <!-- svg -->
                    <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.7218 1.34509L25.1155 6.73879L19.7218 12.1326V1.34509Z" fill="#4F758B"/>
                      <path d="M20.2956 6.73865H12.7218" stroke="#4F758B" stroke-width="1.8667" stroke-miterlimit="10"/>
                      <path d="M2.04861 5.28021V4.14496C2.04861 2.59865 3.30217 1.34509 4.84848 1.34509C6.3948 1.34509 7.64835 2.59865 7.64835 4.14496V5.28021" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
                      <path d="M1 11.7448V5.18939H2.13447H7.58739H8.72185V11.4002H2.60558" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                    <!-- type -->
                    <span class="text-xs text-rGrayMed">{{ action.type }}</span>
                  </div>
                  <div class="flex items-center gap-1 text-sm justify-start w-full">
                    <span>{{ formatAmount(action.amount) }}</span>
                    <span class="text-rGrayMed">{{ action.rri.name.toUpperCase() }}</span>
                  </div>
                </div>
                <!-- column 2 -->
                <div class="grid grid-cols-2 gap-4 justify-between">
                  <div class="flex flex-col md:flex-row items-center gap-1 text-xs justify-start w-full">
                    <span class="text-rGrayDark w-full md:w-24">Validator:</span>
                    <span class="flex items-center w-full">
                      <router-link :to="`/accounts/${action.from_account.toString()}`" class="text-rBlue text-sm">
                          {{ shortenedAddress(action.from_account.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.from_account.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                  <div class="flex flex-col md:flex-row items-center gap-2 text-xs justify-start w-full">
                    <span class="text-rGrayDark w-full md:w-24">Staker:</span>
                    <span class="flex items-center self-end w-full">
                      <router-link :to="`/validators/${action.to_validator.toString()}`" class="text-rBlue text-sm">
                          {{ shortenedValidator(action.to_validator.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.to_validator.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                </div>
              </div>
              <!-- Other -->
              <div v-if="action.type === 'Other'" class="grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 w-full px-3 py-1">
                <!-- column 1 -->
                <div class="grid md:grid-cols-2 justify-between w-full">
                  <div class="flex items-center gap-3 w-full">
                    <!-- svg -->
                    <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.7218 1.34509L25.1155 6.73879L19.7218 12.1326V1.34509Z" fill="#4F758B"/>
                      <path d="M20.2956 6.73865H12.7218" stroke="#4F758B" stroke-width="1.8667" stroke-miterlimit="10"/>
                      <path d="M2.04861 5.28021V4.14496C2.04861 2.59865 3.30217 1.34509 4.84848 1.34509C6.3948 1.34509 7.64835 2.59865 7.64835 4.14496V5.28021" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
                      <path d="M1 11.7448V5.18939H2.13447H7.58739H8.72185V11.4002H2.60558" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                    <!-- type -->
                    <span class="text-xs text-rGrayMed">{{ action.type }}</span>
                  </div>
                  <!-- <div class="flex items-center gap-1 text-sm justify-start w-full">
                    <span>{{ formatAmount(action.amount) }}</span>
                    <span class="text-rGrayMed">{{ action.rri.name.toUpperCase() }}</span>
                  </div> -->
                </div>
                <!-- column 2 -->
                <!-- <div class="grid grid-cols-2 gap-4 justify-between">
                  <div class="flex flex-col md:flex-row items-center gap-1 text-xs justify-start w-full">
                    <span class="text-rGrayDark w-full md:w-24">Validator:</span>
                    <span class="flex items-center w-full">
                      <router-link :to="`/accounts/${action.from_account.toString()}`" class="text-rBlue text-sm">
                          {{ shortenedAddress(action.from_account.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.from_account.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                  <div class="flex flex-col md:flex-row items-center gap-2 text-xs justify-start w-full">
                    <span class="text-rGrayDark w-full md:w-24">Staker:</span>
                    <span class="flex items-center self-end w-full">
                      <router-link :to="`/validators/${action.to_validator.toString()}`" class="text-rBlue text-sm">
                          {{ shortenedValidator(action.to_validator.toString()) }}
                      </router-link>
                      <click-to-copy :text="action.to_validator.toString()" class="text-rGrayDark"/>
                    </span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- show more actions link -->
          <div v-if="txn.actions.length > 3" class="text-center p-2">
            <router-link :to="transactionAddress(txn)" class="flex justify-center text-rBlue text-sm gap-1">
              <span>+ {{ calculateRemainingTransactions(txn.actions.length)}}</span>
              <span>{{ $t('home.moreTransactionsLabel') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!loading" class="flex flex-row items-center text-rGrayDark justify-between p-5 max-w-5xl mx-auto">
    <div>
      <button v-if="canGoBack" class="flex flex-row items-center hover:text-rGreen transition-colors" @click="handlePreviousPage">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-3">
          <g clip-path="url(#clip0)">
          <path d="M9.95508 14.7568L4.83414 9.6359L9.95508 4.51496" class="stroke-current" stroke-miterlimit="10"/>
          <path d="M4.83451 9.63627L15.1655 9.63622" class="stroke-current" stroke-miterlimit="10"/>
          </g>
          <defs>
          <clipPath id="clip0">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
          </defs>
        </svg>
        <span>{{ $t('history.previous') }}</span>
      </button>
    </div>
    <div>
      <button v-if="canGoNext" class="flex flex-row items-center hover:text-rGreen" @click="handleNextPage">
        <span class="mr-3">{{ $t('history.next') }}</span>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
          <path d="M10.0449 5.24316L15.1659 10.3641L10.0449 15.485" class="stroke-current" stroke-miterlimit="10"/>
          <path d="M15.1655 10.3637L4.83446 10.3638" class="stroke-current" stroke-miterlimit="10"/>
          </g>
          <defs>
          <clipPath id="clip0">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed } from 'vue'
import useRadix from '@/composables/useRadix'
import { firstValueFrom } from 'rxjs'
import Observed from '@/helpers/observed'
import { AccountAddressT, AddressTypeT, AmountT, ExecutedAction, ExecutedTransaction, ExecutedTransferTokensAction, NetworkId, ValidatorAddressT } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount, { asBigNumber } from '@/components/BigAmount.vue'
import { formatTransactionAddressForDisplay, formatValidatorAddressForDisplay, formatWalletAddressForDisplay } from '@/helpers/formatter'
import { DateTime } from 'luxon'
import LoadingIcon from '@/components/LoadingIcon.vue'

const PAGE_SIZE = 10

export default defineComponent({
  components: {
    ClickToCopy,
    LoadingIcon
  },
  setup () {
    const { radix, network } = useRadix()

    const recents: Ref<Observed<ReturnType<typeof radix.ledger.recentTransactions>>> = ref({ cursor: '', transactions: [] })
    const cursorStack: Ref<string[]> = ref([])
    const recentTransactionsHistoryIsValid = ref(false) as Ref<boolean>
    const address: Ref<AccountAddressT | null> = ref(null)
    const canGoNext: Ref<boolean> = ref(false)

    const run = async (cursor?: string) => {
      if (!network.value) return
      const params = { size: PAGE_SIZE, network: network.value, cursor }
      recents.value = await firstValueFrom(radix.ledger.recentTransactions(params))
      const data = recents.value

      data.transactions.forEach(d => {
        const { actions } = d
        const alldata = JSON.stringify(d)
        console.log(alldata)
        actions.forEach(action => {
          if (action.type === 'Other') {
            // console.log(actions)
            const json = JSON.stringify(actions)
            const parse = JSON.parse(json)
            // console.log(json)
            // console.log(parse)
          }
        })
      })

      recentTransactionsHistoryIsValid.value = true

      if (data.cursor && data.transactions.length === 10) {
        canGoNext.value = true
      } else {
        canGoNext.value = false
      }
    }

    run()

    const loading = computed(() => {
      return (!network.value || !recentTransactionsHistoryIsValid.value)
    })

    const canGoBack = computed(() => {
      return cursorStack.value.length > 0
    })

    const handleNextPage = () => {
      recentTransactionsHistoryIsValid.value = false
      if (!recents.value) return
      cursorStack.value.push(recents.value.cursor)
      run(cursorStack.value[cursorStack.value.length - 1])
    }

    const handlePreviousPage = () => {
      recentTransactionsHistoryIsValid.value = false
      cursorStack.value.pop()
      run(cursorStack.value.length > 0 ? cursorStack.value[cursorStack.value.length - 1] : '')
    }

    // transform data to display
    const formatAmount = (amount: AmountT) => {
      return asBigNumber(amount)
    }

    const transactionID = (transaction: ExecutedTransaction) => {
      return transaction.txID.toString()
    }

    const shortenedTransactionID = (transaction: ExecutedTransaction) => {
      return formatTransactionAddressForDisplay(transaction.txID)
    }

    const shortenedAddress = (address: AccountAddressT) => {
      return formatWalletAddressForDisplay(address)
    }

    const shortenedValidator = (address: ValidatorAddressT) => {
      return formatValidatorAddressForDisplay(address)
    }

    const sentAt = (transaction: ExecutedTransaction) => {
      return DateTime.fromJSDate(transaction.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
    }

    const transactionAddress = (transaction: ExecutedTransaction) => {
      return `/transactions/${transaction.txID.toString()}`
    }

    const calculateRemainingTransactions = (length: number) => {
      return length - 3
    }

    return {
      address,
      recents,
      cursorStack,
      handleNextPage,
      handlePreviousPage,
      formatAmount,
      shortenedTransactionID,
      transactionID,
      sentAt,
      transactionAddress,
      shortenedAddress,
      shortenedValidator,
      calculateRemainingTransactions,
      loading,
      canGoNext,
      canGoBack
    }
  }
})
</script>
