
import { defineComponent, Ref, watch, ref } from 'vue'
import { ResourceIdentifier } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'
import { useRouter } from 'vue-router'
import BigAmount from '@/components/BigAmount.vue'
import TokenSymbol from '@/components/TokenSymbol.vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import LoadingIcon from '@/components/LoadingIcon.vue'
import useRadix from '@/composables/useRadix'
import Observed from '@/helpers/observed'

export default defineComponent({
  components: {
    LoadingIcon,
    BigAmount,
    ClickToCopy,
    TokenSymbol
  },

  props: {
    rri: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const error: Ref<Error | null> = ref(null)
    const router = useRouter()
    const { radix } = useRadix()
    const resourceIdentifier: Ref<Observed<ReturnType<typeof radix.ledger.tokenInfo>> | null> = ref(null)

    const fetchTokenInfo = async (id: string) => {
      try {
        const initialIdentifier = ResourceIdentifier.fromUnsafe(id.toString())
        if (initialIdentifier.isErr()) {
          throw Error('Invalid RRI')
        }
        resourceIdentifier.value = await firstValueFrom(radix.ledger.tokenInfo(initialIdentifier.value))
      } catch (e) {
        error.value = e
        router.push({ path: '/404', query: { term: props.rri } })
      }
    }

    fetchTokenInfo(props.rri)

    watch(() => props.rri, (newId) => {
      resourceIdentifier.value = null
      fetchTokenInfo(newId.toString())
    })

    return { resourceIdentifier, error }
  }
})
