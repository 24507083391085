import { ref, Ref, computed } from 'vue'
import { HRP, Network, Radix, Token } from '@radixdlt/application'
import { firstValueFrom } from 'rxjs'

export type ChosenNetworkT = {
  network: Network
  networkURL: string
  preamble: string
}

export const network = (networkName: Network): ChosenNetworkT => {
  switch (networkName) {
    case 'localnet':
      return {
        network: Network.LOCALNET,
        networkURL: 'http://localhost:8080',
        preamble: HRP.localnet.account
      }
    case 'mainnet':
      return {
        network: Network.MAINNET,
        networkURL: 'https://olympia-gateway.radixdlt.com',
        preamble: HRP.mainnet.account
      }
    case 'milestonenet':
      return {
        network: Network.MILESTONENET,
        networkURL: 'https://milestonenet-gateway.radixdlt.com',
        preamble: HRP.milestonenet.account
      }
    case 'rcnet':
      return {
        network: Network.RCNET,
        networkURL: 'https://rcnet-gateway.radixdlt.com/',
        preamble: HRP.rcnet.account
      }
    case 'releasenet':
      return {
        network: Network.RELEASENET,
        networkURL: 'https://releasenet-gateway.radixdlt.com/',
        preamble: HRP.releasenet.account
      }
    case 'sandpitnet':
      return {
        network: Network.SANDPITNET,
        networkURL: 'https://sandpitnet-gateway.radixdlt.com',
        preamble: HRP.sandpitnet.account
      }
    case 'stokenet':
      return {
        network: Network.STOKENET,
        networkURL: 'https://stokenet.radixdlt.com',
        preamble: HRP.stokenet.account
      }

    default:
      throw new Error(`Invalid Network Name ${networkName} Provided`)
  }
}
export type RadixInterface = ReturnType<typeof Radix.create>

let radix: RadixInterface = Radix.create()

const connected = ref(false)
const net = process.env.VUE_APP_NETWORK
if (!net || !Object.values<string>(Network).includes(net)) {
  throw new Error(`You must set a valid VUE_APP_NETWORK ${net}`)
}

const activeNetwork = ref(network(net as Network).network)

const nativeToken: Ref<Token | null> = ref(null)

firstValueFrom(radix.ledger.nativeToken(activeNetwork.value)).then((token) => {
  nativeToken.value = token
})

export default function useRadix () {
  return {
    connected: computed(() => connected.value),
    radix,
    network: computed(() => activeNetwork.value),
    nativeToken,

    async establishConnection (): Promise<void> {
      const selectedNetwork: ChosenNetworkT = network(activeNetwork.value)
      activeNetwork.value = selectedNetwork.network
      if (!radix) {
        radix = Radix.create()
      }
      const result = await radix.connect(selectedNetwork.networkURL)
      connected.value = true
    }
  }
}
