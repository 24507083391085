
import { defineComponent } from 'vue'
import { AmountT } from '@radixdlt/application'
import LoadingIcon from '@/components/LoadingIcon.vue'
import ValidatorListItem from '@/components/ValidatorListItem.vue'
import BigNumber from 'bignumber.js'
import Tooltip from '@/components/Tooltip.vue'
import IconArrow from '@/components/IconArrow.vue'
import useRadix from '@/composables/useRadix'
import useValidators from '@/composables/useValidators'

BigNumber.set({
  ROUNDING_MODE: BigNumber.ROUND_FLOOR,
  EXPONENTIAL_AT: [-30, 30]
})

export default defineComponent({
  components: {
    IconArrow,
    LoadingIcon,
    ValidatorListItem,
    Tooltip
  },

  setup () {
    const showTestnetWarning = process.env.VUE_APP_SHOW_TESTNET_WARNINGS === 'true'
    const { radix, network } = useRadix()

    const {
      validators,
      isLoadingValidators: isLoading,
      totalStaked,
      fivePercentStakeValidators,
      threePercentStakeValidators,
      middleValidators,
      bottomValidators,
      fetchValidators
    } = useValidators(radix, network.value)

    fetchValidators()

    return {
      validators,
      isLoading,
      totalStaked,
      fivePercentStakeValidators,
      threePercentStakeValidators,
      middleValidators,
      bottomValidators,
      showTestnetWarning
    }
  },

  methods: {
    stakePercentageLabel (validaterStake: AmountT):string {
      const bigVS = new BigNumber(validaterStake.toString())
      const bigTS = new BigNumber(this.totalStaked.toString())
      return bigVS.dividedBy(bigTS).multipliedBy(100).toFixed(2)
    }
  }
})
