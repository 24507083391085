
import { defineComponent, ref, Ref, computed } from 'vue'
import useRadix from '@/composables/useRadix'
import { firstValueFrom } from 'rxjs'
import Observed from '@/helpers/observed'
import { AccountAddressT, AddressTypeT, AmountT, ExecutedAction, ExecutedTransaction, ExecutedTransferTokensAction, NetworkId, ValidatorAddressT } from '@radixdlt/application'
import ClickToCopy from '@/components/ClickToCopy.vue'
import BigAmount, { asBigNumber } from '@/components/BigAmount.vue'
import { formatTransactionAddressForDisplay, formatValidatorAddressForDisplay, formatWalletAddressForDisplay } from '@/helpers/formatter'
import { DateTime } from 'luxon'
import LoadingIcon from '@/components/LoadingIcon.vue'

const PAGE_SIZE = 10

export default defineComponent({
  components: {
    ClickToCopy,
    LoadingIcon
  },
  setup () {
    const { radix, network } = useRadix()

    const recents: Ref<Observed<ReturnType<typeof radix.ledger.recentTransactions>>> = ref({ cursor: '', transactions: [] })
    const cursorStack: Ref<string[]> = ref([])
    const recentTransactionsHistoryIsValid = ref(false) as Ref<boolean>
    const address: Ref<AccountAddressT | null> = ref(null)
    const canGoNext: Ref<boolean> = ref(false)

    const run = async (cursor?: string) => {
      if (!network.value) return
      const params = { size: PAGE_SIZE, network: network.value, cursor }
      recents.value = await firstValueFrom(radix.ledger.recentTransactions(params))
      const data = recents.value

      data.transactions.forEach(d => {
        const { actions } = d
        const alldata = JSON.stringify(d)
        console.log(alldata)
        actions.forEach(action => {
          if (action.type === 'Other') {
            // console.log(actions)
            const json = JSON.stringify(actions)
            const parse = JSON.parse(json)
            // console.log(json)
            // console.log(parse)
          }
        })
      })

      recentTransactionsHistoryIsValid.value = true

      if (data.cursor && data.transactions.length === 10) {
        canGoNext.value = true
      } else {
        canGoNext.value = false
      }
    }

    run()

    const loading = computed(() => {
      return (!network.value || !recentTransactionsHistoryIsValid.value)
    })

    const canGoBack = computed(() => {
      return cursorStack.value.length > 0
    })

    const handleNextPage = () => {
      recentTransactionsHistoryIsValid.value = false
      if (!recents.value) return
      cursorStack.value.push(recents.value.cursor)
      run(cursorStack.value[cursorStack.value.length - 1])
    }

    const handlePreviousPage = () => {
      recentTransactionsHistoryIsValid.value = false
      cursorStack.value.pop()
      run(cursorStack.value.length > 0 ? cursorStack.value[cursorStack.value.length - 1] : '')
    }

    // transform data to display
    const formatAmount = (amount: AmountT) => {
      return asBigNumber(amount)
    }

    const transactionID = (transaction: ExecutedTransaction) => {
      return transaction.txID.toString()
    }

    const shortenedTransactionID = (transaction: ExecutedTransaction) => {
      return formatTransactionAddressForDisplay(transaction.txID)
    }

    const shortenedAddress = (address: AccountAddressT) => {
      return formatWalletAddressForDisplay(address)
    }

    const shortenedValidator = (address: ValidatorAddressT) => {
      return formatValidatorAddressForDisplay(address)
    }

    const sentAt = (transaction: ExecutedTransaction) => {
      return DateTime.fromJSDate(transaction.sentAt).toLocaleString(DateTime.DATETIME_SHORT)
    }

    const transactionAddress = (transaction: ExecutedTransaction) => {
      return `/transactions/${transaction.txID.toString()}`
    }

    const calculateRemainingTransactions = (length: number) => {
      return length - 3
    }

    return {
      address,
      recents,
      cursorStack,
      handleNextPage,
      handlePreviousPage,
      formatAmount,
      shortenedTransactionID,
      transactionID,
      sentAt,
      transactionAddress,
      shortenedAddress,
      shortenedValidator,
      calculateRemainingTransactions,
      loading,
      canGoNext,
      canGoBack
    }
  }
})
