<template>
  <tr class="bg-white md:border-l md:border-r md:border-solid md:hidden
  border-rGrayLightest text-left" :class="{ 'opacity-30': percentStaked >= 3 }">
    <div class="mx-auto bg-rGrayLight border w-full rounded-t grid grid-cols-2 py-1 text-rGrayDark pl-4 mt-2">
      <router-link class="whitespace-nowrap inline-flex pt-2" :to="`/validators/${validator.address.toString()}`">
        <div class="pr-2">Validator:</div>
        <div v-if="this.validator.name" class=" font-medium text-rBlue w-40 truncate">{{ validatorName }}</div>
        <div v-else class=" text-rBlue italic w-40 truncate">{{ validatorName }}</div>
      </router-link>
      <template v-if="this.validator.infoURL && validatedValidatorUrl">
        <Tooltip
          textKey="validators.validatorWarning"
          class="cursor-default inline-flex justify-end pr-4 underline"
          :isWarning="true"
          :rightmost="true"
        >
        Website:
          <a :href="this.validator.infoURL" target="__blank">
            <svg width="16" height="16" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="mt-1.5">
              <path d="M3.66797 3.60429H10.9101V10.8464" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
              <path d="M10.9096 3.60429L3.60449 10.9095" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
            </svg>
          </a>
        </Tooltip>
      </template>
    </div>
  <div class="px-4 border-l border-r w-full grid grid-cols-3 text-rGrayDark">
    <div class="col-span-2 flex py-2">
      <svg width="13" height="15" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-1 mr-1">
        <path d="M2.04883 4.93512V3.79987C2.04883 2.25355 3.30238 1 4.8487 1C6.39502 1 7.64857 2.25355 7.64857 3.79987V4.93512" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M1 11.3997V4.84432H2.13447H7.58739H8.72185V11.0552H2.60558" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
      </svg>
      <span class="text-rGrayDark ml-1">Stake</span>
      <span class="text-rBlack ml-2"> {{ displayAmount(validator.totalDelegatedStake) }} </span>
      <span v-if="percentStaked" class="text-rGrayDark ml-1"> ({{ formatPercentage(percentStaked) }}%) </span>
    </div>
    <div class="col-span-1">
       <Tooltip v-if="validator.isExternalStakeAccepted" textKey="validator.greenTooltip" class="cursor-default inline-flex justify-end">
        ADS
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-1.5 ml-2">
          <circle cx="7" cy="7" r="6.5" transform="rotate(90 7 7)" fill="#00C389" stroke="#00C389"/>
          <path d="M6.00061 10.4128L3.5 7.9121L4.24235 7.16968L5.94181 8.86893L9.94665 4.2L10.7437 4.88362L6.00061 10.4128Z" fill="white"/>
        </svg>
      </Tooltip>
      <Tooltip v-else textKey="validator.yellowTooltip" class="cursor-default inline-flex justify-end" >
        ADS
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-1.5 ml-2">
          <circle cx="7" cy="7" r="7" transform="rotate(90 7 7)" fill="#F9D100"/>
          <path d="M3 7H11" stroke="white" stroke-width="1.5"/>
        </svg>
      </Tooltip>
    </div>
    <div class="">
      Owner Stake:
    </div>
    <div class="mx-auto">
      Fee %
    </div>
    <div class="ml-auto">
      Recent Uptime:
    </div>
    <div class="text-rGrayDarkest">
      {{ displayAmount(validator.ownerDelegation) }}
    </div>
    <div class="text-rGrayDarkest mx-auto">
      {{ validator.validatorFee }}%
    </div>
    <div class="text-rGrayDarkest mb-4 ml-auto">
      {{ uptimeContent }}
    </div>

  </div>
  <div class="px-4 bg-rGrayLight border w-full rounded-b mb-4 grid grid-cols-2 text-rGrayDark py-3">
    <div>
      Address:
    </div>
    <div>
    <td class="whitespace-nowrap text-center
    flex items-center justify-end">
      <router-link class="text-rBlue font-mono text-xs" :to="`/validators/${validator.address.toString()}`">{{ validatorAddress }}</router-link>
      <click-to-copy :text="validator.address.toString()" />
    </td>
    </div>
  </div>
  </tr>
  <!-- Desktop variant -->
  <tr class="bg-white border-l border-r border-solid
  border-rGrayLightest text-left hidden md:table-row" :class="{ 'opacity-30': percentStaked >= 3 }">
    <td class="px-4 py-4 h-14 bg-rGrayLight border-l border-r border-solid
    border-rGrayLight">
      <router-link class="whitespace-nowrap" :to="`/validators/${validator.address.toString()}`">
        <div v-if="this.validator.name" class="text-sm font-medium text-rBlue w-40 truncate">{{ validatorName }}</div>
        <div v-else class="text-sm text-rBlue italic w-40 truncate">{{ validatorName }}</div>
      </router-link>
    </td>
    <td class="px-4 py-4 h-14 whitespace-nowrap text-sm text-rBlack text-center
    flex items-center justify-start">
      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.04883 4.93512V3.79987C2.04883 2.25355 3.30238 1 4.8487 1C6.39502 1 7.64857 2.25355 7.64857 3.79987V4.93512" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M1 11.3997V4.84432H2.13447H7.58739H8.72185V11.0552H2.60558" stroke="#7A99AC" stroke-width="1.5" stroke-miterlimit="10"/>
      </svg>
      <span class="text-rGrayDark ml-1">Stake</span>
      <span class="text-rBlack ml-2"> {{ displayAmount(validator.totalDelegatedStake) }} </span>
      <span v-if="percentStaked" class="text-rGrayDark ml-1"> ({{ formatPercentage(percentStaked) }}%) </span>
    </td>
    <td class="py-4 h-14 whitespace-nowrap text-sm text-rBlack text-center">
      <Tooltip v-if="validator.isExternalStakeAccepted" textKey="validator.greenTooltip" class="cursor-default inline-flex justify-center">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="7" r="6.5" transform="rotate(90 7 7)" fill="#00C389" stroke="#00C389"/>
          <path d="M6.00061 10.4128L3.5 7.9121L4.24235 7.16968L5.94181 8.86893L9.94665 4.2L10.7437 4.88362L6.00061 10.4128Z" fill="white"/>
        </svg>
      </Tooltip>
      <Tooltip v-else textKey="validator.yellowTooltip" class="cursor-default inline-flex justify-center">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="7" r="7" transform="rotate(90 7 7)" fill="#F9D100"/>
          <path d="M3 7H11" stroke="white" stroke-width="1.5"/>
        </svg>
      </Tooltip>
    </td>
    <td class="px-4 py-4 h-14 whitespace-nowrap text-sm text-rBlack text-center justify-center">
      {{ displayAmount(validator.ownerDelegation) }}
    </td>
    <td class="px-4 py-4 h-14 whitespace-nowrap text-sm text-rBlack text-center">
      {{ validator.validatorFee }}%
    </td>
    <td
      class="px-4 py-4 h-14 whitespace-nowrap text-sm text-rBlack text-center"
      :class="{'text-rRed': uptime <= 98}"
    >
      {{ uptimeContent }}
    </td>
    <td class="px-4 py-4 h-14 whitespace-nowrap text-sm text-rBlack text-center
    flex items-center justify-center">
      <router-link class="text-rBlue font-mono text-xs" :to="`/validators/${validator.address.toString()}`">{{ validatorAddress }}</router-link>
      <click-to-copy :text="validator.address.toString()" />
    </td>
    <td class="h-14 whitespace-nowrap text-sm text-rBlack bg-rOffwhite
    text-center">
      <template v-if="this.validator.infoURL && validatedValidatorUrl">
        <Tooltip
          textKey="validators.validatorWarning"
          class="cursor-default inline-flex justify-center"
          :isWarning="true"
          :rightmost="true"
        >
          <a :href="this.validator.infoURL" target="__blank">
            <svg width="13" height="13" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="m-auto">
              <path d="M3.66797 3.60429H10.9101V10.8464" stroke="#7A99AC" stroke-miterlimit="10"/>
              <path d="M10.9096 3.60429L3.60449 10.9095" stroke="#7A99AC" stroke-miterlimit="10"/>
            </svg>
          </a>
        </Tooltip>
      </template>
    </td>
  </tr>
  <tr class="p-0" :class="{ 'opacity-30' : percentStaked >= 3 }">
    <td colspan="1" class="h-px md:bg-rGrayLight p-0"></td>
    <td colspan="7" class="h-px md:bg-gray-200 p-0 border-r border-solid
    border-rGrayLightest"></td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ClickToCopy from '@/components/ClickToCopy.vue'
import { Validator, AmountT } from '@radixdlt/application'
import BigNumber from 'bignumber.js'
import { formatValidatorAddressForDisplay } from '@/helpers/formatter'
import Tooltip from '@/components/Tooltip.vue'
import { checkValidatorUrlExploitable } from '@/helpers/urlHelpers'

export default defineComponent({
  components: {
    ClickToCopy,
    Tooltip
  },

  props: {
    validator: {
      type: Object as PropType<Validator>,
      required: true
    },
    percentStaked: {
      type: String,
      required: false
    },
    hideUptime: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    validatorName (): string {
      if (this.validator.name) return this.validator.name
      return 'No name provided'
    },
    validatorAddress (): string {
      return formatValidatorAddressForDisplay(this.validator.address)
    },
    validatorURL (): string {
      return `/#/validators/${this.validator.address.toString()}`
    },
    uptime (): string {
      return (Math.round(Number(this.validator.uptimePercentage) * 10) / 10).toFixed(1)
    },
    uptimeContent (): string {
      return this.hideUptime
        ? '-'
        : `${this.uptime}%`
    },
    validatedValidatorUrl (): boolean {
      if (!this.validator) {
        return false
      }
      return checkValidatorUrlExploitable(this.validator.infoURL.toString())
    }
  },

  methods: {
    displayAmount (amount: AmountT): string {
      const bigNumber = new BigNumber(amount.toString())
      const shiftedAmount = bigNumber.shiftedBy(-18) // Atto
      const bigOne = new BigNumber(1)
      const bigZero = new BigNumber(0)
      if (shiftedAmount.isGreaterThan(bigOne)) {
        return shiftedAmount.toFormat(0)
      } else if (shiftedAmount.isGreaterThan(bigZero)) {
        return '< 1'
      } else {
        return '0'
      }
    },
    formatPercentage (percentStaked: number): number {
      return +(Math.floor(100 * percentStaked) / 100).toFixed(2)
    }
  }
})
</script>
