<template>
  <div>
    <form class="mt-1 relative rounded-md group" @submit.prevent="handleSubmit">
      <button
        type="submit"
        class="absolute inset-y-0 right-0 flex items-center focus:outline-none border border-transparent"
      >
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{
            'mx-2 lg:mx-4 h-6 lg:h-auto stroke-current': true,
            'text-rGreen hover:text-rGreenDark': !hasError,
            'text-rRed hover:text-rRed': hasError
          }"
        >
          <path
            d="M1.1975 18.6392C1.8145 21.0305 3.0593 23.2933 4.9319 25.1659C5.3812 25.6152 5.853 26.0284 6.3436 26.4054C11.9519 30.7152 20.0226 30.3021 25.1587 25.1659C30.7442 19.5804 30.7442 10.5246 25.1587 4.93909C19.5732 -0.646405 10.5174 -0.646405 4.9319 4.93909C3.177 6.69399 1.9735 8.79149 1.3213 11.0162C1.0495 11.9433 0.873503 12.8926 0.793203 13.8484C0.772003 14.1014 0.757403 14.3548 0.749603 14.6084"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
          <path
            d="M25.1587 25.1659L37.1465 37.1537"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
        </svg>
      </button>
      <input
        type="text"
        name="price"
        id="price"
        placeholder="Search by Address, Transaction ID, Validator ID, or Token RRI"
        :class="{
          'focus:ring-0 block text-xs lg:text-base w-full pl-2 pr-12 py-2 lg:pl-4 lg:py-4 rounded-md': true,
          'border-rGray focus:border-rGrayMed': !hasError,
          'border-rRed focus:border-rRed': hasError
        }"
        v-model="term"
      />
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import {
  safelyUnwrapAddress,
  safelyUnwrapRRI,
  safelyUnwrapValidator
} from '@/helpers/validateRadixTypes'
import { TransactionIdentifier } from '@radixdlt/application'
import useRadix from '@/composables/useRadix'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const hasError = ref(false)
    const term = ref('')
    const { network } = useRadix()
    const router = useRouter()

    return {
      network,
      hasError,
      term,
      handleSubmit () {
        const trimmedTerm = term.value.trim()
        const accountAddress = safelyUnwrapAddress(trimmedTerm)
        const validatorAddress = safelyUnwrapValidator(trimmedTerm)
        const rri = safelyUnwrapRRI(trimmedTerm)
        if (accountAddress && accountAddress.network === network.value) {
          router.push({ path: `/accounts/${trimmedTerm}` })
          term.value = ''
          hasError.value = false
          return
        } else if (validatorAddress && validatorAddress.network === network.value) {
          router.push({ path: `/validators/${trimmedTerm}` })
          term.value = ''
          hasError.value = false
          return
        } else if (rri) {
          router.push({ path: `/tokens/${trimmedTerm}` })
          term.value = ''
          hasError.value = false
          return
        }
        try {
          const identifier = TransactionIdentifier.create(trimmedTerm)
          if (identifier.isOk()) {
            router.push({ path: `/transactions/${trimmedTerm}` })
            term.value = ''
            hasError.value = false
            return
          }
        } catch {
          // Error thrown while creating transaction identifier
        }
        hasError.value = true
        router.push({ path: '/404', query: { term: trimmedTerm } })
      }
    }
  }
})
</script>
